.input-text {
  border-radius: 3px !important;
  background: #F9F9F9 !important;
  border: 1px solid #D9D9D9 !important;
  width: 100%;
}

.form-control:focus {
  border: 1px solid;
  border-color: #388e3c !important;
  outline: none; 
}

.label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #4D4D4D;
  margin: 0;
}