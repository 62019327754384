.title-filter {
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000;
}
.table-print {
  font-size: 10px;
}

.head-title {
  width: 300px !important;
}

.bord-r {
  border-right: 1px solid #A7C096;
}

.filter-alert span {
  font-size: 16px;
  color: #fff;
}

.table-bordered-print th.table-bordered-print td {
  border: 10px solid #A7C096 !important;
}

.resume {
  font-weight: bold;
}

.bg-filter th {
  color:#333;
  background-color: rgb(96, 156, 70);
}