
.crop-img {
  /* height: 150px; */
}

.card-team p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.card-team span {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

.card-team-func {
  background: #FFFFFF;
  border-radius: 3px;
}

.card-team-func p{
  color: #CFCFCF;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
}

.card-team-func span{
  color: #505050;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px
}

.card-total {
  border: 3px solid #53883D;
  border-radius: 12px;

}

.card-total span {
  margin: 5px;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #505050;
}

.card-total p {
  margin: 5px;;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
}

.img-employee {
  border-radius: 50%;
  max-width: 60px;
  /* border: solid 2px white */
  /* max-height: 50px;
  max-width: 50px; */
}

/* .employee-text  {
  line-height: 10px;
} */

.employee-text p {
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.employee-text span {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}