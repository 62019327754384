.scrollme {
  max-width:100%;
  height:800px;
  overflow-x: scroll;
  overflow-y: scroll;
  white-space: nowrap;
  display: block;
  padding: 0;
}
.scrollme thead th { 
  position: sticky; 
  top: 0; 
}

.scrollme-resume {
    width:100%;
    height:150px;
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
    padding: 0;
}
.scrollme-resume thead th { 
  position: sticky; 
  top: 0; 
}

.table .thead-dark th {
  color: #fff;
  background-color: #53883D;
}

.table .thead-report th {
  color: #222529;
  background-color: #98c884;
}

.table-bordered th,.table-bordered td {
  border: 1px solid #A7C096 !important;
}

.ref-month {
  font-weight: bold;
  font-size: 15px;
}

.headcol {
  position: absolute;
  z-index: 1;
  left:0;
}

.headcol-id {
  width: 50px !important;
}

.headcol-title {
  width: 100px !important;
}

.headcol-content {
  position:sticky;
  left:0 !important;
  background-color: rgba(245, 245, 245, 0.989) !important;
  font-size: small;
}

.budget-alert {
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
  z-index: 10;
  top: 2%;
  position: sticky;
} 

.bg-table-budget {
  display: flex;
  align-items: center;
  background-color: #f2f4f2;
}

.bg-table-budget span{
  color: #666;
  font-weight: bold;
  font-size: medium;
}

.freeze {
  position: sticky; top: 0;
}
