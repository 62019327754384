
.crop-img {
  /* height: 150px; */
}

.card-tillage p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.card-tillage span {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
}

.card-tillage-ha {
  background: #FFFFFF;
  border-radius: 3px;
}

.card-tillage-ha span{
  color: #505050;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px
}

.card-total {
  border: 3px solid #53883D;
  border-radius: 12px;

}

.card-total span {
  margin: 5px;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #505050;
}

.card-total p {
  margin: 5px;;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #505050;
}