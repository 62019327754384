.show-from-right {
  transform: translate3d(-280px, 0, 0); 
}

.right-sidebar-outer {
  transition: transform 0.25s ease;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 280px;
  height: 100%;
  left: 100%;
  display: block;
  overflow: hidden;
  z-index: 1036;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); 
}

.right-sidebar-outer .right-sidebar-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  width: 300px; 
}

.right-sidebar-outer .right-sidebar {
  width: 280px;
  text-align: left; 
}

.right-sidebar-outer .right-sidebar h4 {
  padding: 20px;
  font-size: 0.9375rem;
  margin-bottom: 0; 
}

.right-sidebar-outer .right-sidebar .tab-content {
  text-align: left;
  padding: 10px; 
}

.right-sidebar-outer .right-sidebar .color-block {
  display: block;
  height: 30px;
  width: 30px;
  float: left;
  margin: 0 10px 5px 0;
  cursor: pointer; 
}

.list-group-item {
  border: none !important;
}
.list-group-item a{
  text-decoration: none !important;
}

.right-sidebar-outer .right-sidebar .list-group-item-title {
  font-size: 0.8125rem;
  font-weight: 400; 
}

.list-group-item-layout {
  cursor: pointer !important;
}

.right-sidebar-outer .right-sidebar .list-group-item-colors {
  padding: 20px 20px; 
}

[data-background="light"] .right-sidebar-outer {
  background: #ffffff;
  color: #212121; 
}

[data-background="light"] .right-sidebar-outer a {
  color: #212121; 
}

[data-background="light"] .right-sidebar .list-group-item-title {
  background: #ffffff;
  color: #212121; 
}

[data-background="dark"] .right-sidebar-outer {
  background: #212121;
  color: #ffffff; 
}

[data-background="dark"] .right-sidebar-outer a {
  color: #ffffff; 
}

[data-background="dark"] .right-sidebar .list-group-item-title {
  background: #212121;
  color: #ffffff; 
}

[data-background="primary"] .right-sidebar-outer {
  background: #303f9f;
  color: #ffffff; 
}

[data-background="primary"] .right-sidebar-outer a {
  color: #ffffff; 
}

[data-background="primary"] .right-sidebar .list-group-item-title {
  background: #303f9f;
  color: #ffffff; 
}

