.table-print {
  font-size: 10px;
}
.table-print th{
  background-color: #A7C096  !important;
  color: #464646 !important;
}

.head-title {
  width: 300px !important;
}

.table-bordered-print th,.table-bordered-print td {
  border: 1px solid #A7C096 !important;
}
