/* a:hover {
  font-weight: bold;
} */

.agri-input-dash h1 {
  font-size: 16px;
  font-weight: bold;
  color: #53883D;
}

.irrigation-dash h1 {
  font-size: 16px;
  font-weight: bold;
  color: #0B617A;
}

.time-machine-dash h1 {
  font-size: 16px;
  font-weight: bold;
  color: #0B4050;
}

.budget-dash h1 {
  font-size: 16px;
  font-weight: bold;
  color: #53883d;
}

.irrigation-time {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.irrigation-time span{
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  /* line-height: 36px; */
  color: #FFFFFF;
}

.irrigation-crop {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  color: #FFFFFF;
}

.select-budget {
  font-style: normal;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #999;
}

.dash-loading {
  display: flex;
  flex-direction: row;
  align-self: center;
  left: 50%;
}

.cargo-value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

.hour {
  display: flex;
  align-items: baseline;
}

.cargo-currency {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 10px;
  display: flex;
  color: #FFFFFF;
}

.cargo-crop {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}

/* .crops {
  max-width: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .crops img {
  max-width:100%;
  height:100%;
} */

/* .crop-title {
  color: #505050;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
} */
/* 
.crop-date {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #505050;
} */

/* .crop-number-plants {
  font-style: normal;
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  color: #53883D;
  margin-top: -15px;
} */

.card-img {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.no-border {
  border: none !important;
}