.widget {
  width: 100%;
  padding: 0 10px 0 10px;
  margin-top: 10px;  
  margin-bottom: 100px; 
  font-family: 'Poppins';
  min-height: 100vh;
}

.widget > .row {
  margin-bottom: 20px; 
}

.widget .material-icons {
  font-size: 20px !important;
}

.widget > .row:last-child {
  margin-bottom: 0; 
}

.widget .title {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 54px;
  text-transform: uppercase;
  /* color: rgba(80, 80, 80, 0.15); */
  color: #999
}

.widget .line {
  margin: 0 0 20px 0;
  border: 0.7px solid #505050;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
}

.widget.dashboard-widget .right-icons > i {
  color: #616161;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 1.125rem; 
}

[data-background="light"] .widget {
  background-color: #ffffff;
  color: #212121; 
}

[data-background="dark"] .widget {
  background-color: #212121;
  color: #ffffff; 
}

[data-background="primary"] .widget {
  background-color: #303f9f;
  color: #ffffff; 
}

@media (max-width: 768px) {
  .widget .title {
    font-size: 16px;
    margin-top: 15px;
  }
}