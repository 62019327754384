.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.label-input {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

/* .inputfile:focus label,
.inputfile label:hover {
    background-color: red;
} */

