/*!
* Bootstrap v4.0.0-beta (https://getbootstrap.com)
* Copyright 2011-2017 The Bootstrap Authors
* Copyright 2011-2017 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/

@import '../../node_modules/react-toastify/dist/ReactToastify.css';
@import '../../node_modules/cropperjs/dist/cropper.css';
/* @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import './components/buttons/buttons.css';
@import './react-datatable.css';
@import '../components/modals/modals.css';
@import '../css/components/lists/lists.css';

*,
*::before,
*::after {
  box-sizing: border-box; 
}

html {
  /* font-family: "Roboto" sans-serif; */
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  font-size: 0.95em;
  -webkit-text-size-adjust: 95%;
  -ms-text-size-adjust: 95%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); 
}

@-ms-viewport {
  width: device-width; 
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; 
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212121;
  text-align: left;
  background-color: #fff; 
}

.link {
  cursor: pointer;
}

.financial-link-hover a:hover{
  color: #2a692d;
  font-weight: bold;
}

.custom-control-input:disabled {
  background-color: #2a692d !important;
}

.line-list p {
  color: #4D4D4D !important;
  font-family: Poppins;
  font-style: normal !important;
  font-weight: bold !important;
}

.line-list span {
  font-size: 18px;
  background: #79C047;
  margin-right: -14px;
}

[tabindex="-1"]:focus {
  outline: none !important; 
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; 
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; 
}

p {
  margin-top: 0;
  margin-bottom: 1rem; 
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; 
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; 
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; 
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; 
}

dt {
  font-weight: bold; 
}


dd {
margin-bottom: 0.5rem;
margin-left: 0; 
}

blockquote {
margin: 0 0 1rem; 
}

dfn {
font-style: italic; 
}


b,
strong {
font-weight: bolder; 
}


small {
font-size: 80%; 
}

sub,
sup {
position: relative;
font-size: 75%;
line-height: 0;
vertical-align: baseline; 
}

sub {
bottom: -0.25em; 
}

sup {
top: -0.5em; 
}

a {
color: #303f9f;
text-decoration: none;
background-color: transparent;
-webkit-text-decoration-skip: objects; 
}

a:hover {
color: #1e2864;
text-decoration: underline; 
}


a:not([href]):not([tabindex]) {
color: inherit;
text-decoration: none; 
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
color: inherit;
text-decoration: none; 
}

a:not([href]):not([tabindex]):focus {
outline: 0; 
}

/* added Joel */
a {
  text-decoration: none !important;
}

pre,
code,
kbd,
samp {
font-family: monospace, monospace;
font-size: 1em; 
}

pre {
margin-top: 0;
margin-bottom: 1rem;
overflow: auto; 
}


figure {
margin: 0 0 1rem; 
}


img {
vertical-align: middle;
border-style: none; 
}


svg:not(:root) {
overflow: hidden; 
}


a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
touch-action: manipulation; 
}

table {
border-collapse: collapse; 
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #757575;
  text-align: left;
  caption-side: bottom; 
}

th {
  text-align: inherit; 
}

label {
  display: inline-block;
  margin-bottom: 0.5rem; 
  color: #4D4D4D;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; 
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; 
}
/* added Joel */
button {
  /* border-radius: 0 !important; */
}

button,
input {
  overflow: visible; 
}


button,
select {
  text-transform: none; 
}


button,
html [type="button"],
[type="submit"] {
-webkit-appearance: button; 
}


button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; 
}


input[type="radio"],
input[type="file"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; 
}


input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; 
}

textarea {
  overflow: auto;
  resize: vertical; 
}

fieldset {
min-width: 0;
padding: 0;
margin: 0;
border: 0; 
}


legend {
display: block;
width: 100%;
max-width: 100%;
padding: 0;
margin-bottom: 0.5rem;
font-size: 1.5rem;
line-height: inherit;
color: inherit;
white-space: normal; 
}


progress {
vertical-align: baseline; 
}


[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
height: auto; 
}


[type="search"] {
outline-offset: -2px;
-webkit-appearance: none; 
}


[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
-webkit-appearance: none; 
}


::-webkit-file-upload-button {
font: inherit;
-webkit-appearance: button; 
}


output {
display: inline-block; 
}


summary {
display: list-item; 
}


template {
display: none; 
}


[hidden] {
display: none !important; 
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
margin-bottom: 0.5rem;
font-family: inherit;
font-weight: normal;
line-height: 1.1;
color: inherit; 
}


h1,
.h1 {
font-size: 2.5rem; 
}


h2,
.h2 {
font-size: 2rem; 
}


h3,
.h3 {
font-size: 1.75rem; 
}


h4,
.h4 {
font-size: 1.5rem; 
}


h5,
.h5 {
font-size: 1.25rem; 
}


h6,
.h6 {
font-size: 1rem; 
}


.lead {
font-size: 1.25rem;
font-weight: 300; 
}


.display-1 {
font-size: 6rem;
font-weight: normal;
line-height: 1.1; 
}


.display-2 {
font-size: 5.5rem;
font-weight: normal;
line-height: 1.1; 
}


.display-3 {
font-size: 4.5rem;
font-weight: normal;
line-height: 1.1; 
}


.display-4 {
font-size: 3.5rem;
font-weight: normal;
line-height: 1.1; 
}


hr {
margin-top: 1rem;
margin-bottom: 1rem;
border: 0;
border-top: 1px solid rgba(0, 0, 0, 0.1); 
}


small,
.small {
font-size: 80%;
font-weight: normal; 
}


mark,
.mark {
padding: 0.2em;
background-color: #fcf8e3; 
}


.list-unstyled {
padding-left: 0;
list-style: none; 
}


.list-inline {
padding-left: 0;
list-style: none; 
}


.list-inline-item {
display: inline-block; 
}

.list-inline-item:not(:last-child) {
margin-right: 5px; 
}


.initialism {
font-size: 90%;
text-transform: uppercase; 
}


.blockquote {
margin-bottom: 1rem;
font-size: 1.25rem; 
}


.blockquote-footer {
display: block;
font-size: 80%;
color: #757575; 
}

.blockquote-footer::before {
content: "\2014 \00A0"; 
}


.img-fluid {
max-width: 100%;
height: auto; 
}


.img-thumbnail {
padding: 0.25rem;
background-color: #fff;
border: 1px solid #ddd;
max-width: 100%;
height: auto; 
}


.figure {
display: inline-block; 
}


.figure-img {
margin-bottom: 0.5rem;
line-height: 1; 
}


.figure-caption {
font-size: 90%;
color: #757575; 
}


code,
kbd,
pre,
samp {
font-family: "Inconsolata", "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; 
}


code {
padding: 0.2rem 0.4rem;
font-size: 90%;
color: #bd4147;
background-color: #f5f5f5; 
}

a > code {
padding: 0;
color: inherit;
background-color: inherit; 
}


kbd {
padding: 0.2rem 0.4rem;
font-size: 90%;
color: #fff;
background-color: #212121; 
}

kbd kbd {
padding: 0;
font-size: 100%;
font-weight: bold; 
}


pre {
display: block;
margin-top: 0;
margin-bottom: 1rem;
font-size: 90%;
color: #212121; 
}

pre code {
padding: 0;
font-size: inherit;
color: inherit;
background-color: transparent;
border-radius: 0; 
}


.pre-scrollable {
max-height: 340px;
overflow-y: scroll; 
}


.container {
margin-right: auto;
margin-left: auto;
padding-right: 10px;
padding-left: 10px;
width: 100%; 
}

@media (min-width: 576px) {
.container {
max-width: 540px; 
}

}

@media (min-width: 768px) {
.container {
max-width: 720px; 
}

}

@media (min-width: 992px) {
.container {
max-width: 960px; 
}

}

@media (min-width: 1200px) {
.container {
max-width: 1140px; 
}

}


.container-fluid {
width: 100%;
margin-right: auto;
margin-left: auto;
padding-right: 10px;
padding-left: 10px;
width: 100%; 
}


.row {
display: flex;
flex-wrap: wrap;
margin-right: -10px;
margin-left: -10px; 
}


.no-gutters {
margin-right: 0;
margin-left: 0; 
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
padding-right: 0;
padding-left: 0; 
}


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
position: relative;
width: 100%;
min-height: 1px;
padding-right: 10px;
padding-left: 10px; 
}


.col {
flex-basis: 0;
flex-grow: 1;
max-width: 100%; 
}


.col-auto {
flex: 0 0 auto;
width: auto;
max-width: none; 
}


.col-1 {
flex: 0 0 8.33333%;
max-width: 8.33333%; 
}


.col-2 {
flex: 0 0 16.66667%;
max-width: 16.66667%; 
}


.col-3 {
flex: 0 0 25%;
max-width: 25%; 
}


.col-4 {
flex: 0 0 33.33333%;
max-width: 33.33333%; 
}


.col-5 {
flex: 0 0 41.66667%;
max-width: 41.66667%; 
}


.col-6 {
flex: 0 0 50%;
max-width: 50%; 
}


.col-7 {
flex: 0 0 58.33333%;
max-width: 58.33333%; 
}


.col-8 {
flex: 0 0 66.66667%;
max-width: 66.66667%; 
}


.col-9 {
flex: 0 0 75%;
max-width: 75%; 
}


.col-10 {
flex: 0 0 83.33333%;
max-width: 83.33333%; 
}


.col-11 {
flex: 0 0 91.66667%;
max-width: 91.66667%; 
}


.col-12 {
flex: 0 0 100%;
max-width: 100%; 
}


.order-1 {
order: 1; 
}


.order-2 {
order: 2; 
}


.order-3 {
order: 3; 
}


.order-4 {
order: 4; 
}


.order-5 {
order: 5; 
}


.order-6 {
order: 6; 
}


.order-7 {
order: 7; 
}


.order-8 {
order: 8; 
}


.order-9 {
order: 9; 
}


.order-10 {
order: 10; 
}


.order-11 {
order: 11; 
}


.order-12 {
order: 12; 
}


@media (min-width: 576px) {
.col-sm {
flex-basis: 0;
flex-grow: 1;
max-width: 100%; 
}

.col-sm-auto {
flex: 0 0 auto;
width: auto;
max-width: none; 
}

.col-sm-1 {
flex: 0 0 8.33333%;
max-width: 8.33333%; 
}

.col-sm-2 {
flex: 0 0 16.66667%;
max-width: 16.66667%; 
}

.col-sm-3 {
flex: 0 0 25%;
max-width: 25%; 
}

.col-sm-4 {
flex: 0 0 33.33333%;
max-width: 33.33333%; 
}

.col-sm-5 {
flex: 0 0 41.66667%;
max-width: 41.66667%; 
}

.col-sm-6 {
flex: 0 0 50%;
max-width: 50%; 
}

.col-sm-7 {
flex: 0 0 58.33333%;
max-width: 58.33333%; 
}

.col-sm-8 {
flex: 0 0 66.66667%;
max-width: 66.66667%; 
}

.col-sm-9 {
flex: 0 0 75%;
max-width: 75%; 
}

.col-sm-10 {
flex: 0 0 83.33333%;
max-width: 83.33333%; 
}

.col-sm-11 {
flex: 0 0 91.66667%;
max-width: 91.66667%; 
}

.col-sm-12 {
flex: 0 0 100%;
max-width: 100%; 
}

.order-sm-1 {
order: 1; 
}

.order-sm-2 {
order: 2; 
}

.order-sm-3 {
order: 3; 
}

.order-sm-4 {
order: 4; 
}

.order-sm-5 {
order: 5; 
}

.order-sm-6 {
order: 6; 
}

.order-sm-7 {
order: 7; 
}

.order-sm-8 {
order: 8; 
}

.order-sm-9 {
order: 9; 
}

.order-sm-10 {
order: 10; 
}

.order-sm-11 {
order: 11; 
}

.order-sm-12 {
order: 12; 
}

}


@media (min-width: 768px) {
.col-md {
flex-basis: 0;
flex-grow: 1;
max-width: 100%; 
}

.col-md-auto {
flex: 0 0 auto;
width: auto;
max-width: none; 
}

.col-md-1 {
flex: 0 0 8.33333%;
max-width: 8.33333%; 
}

.col-md-2 {
flex: 0 0 16.66667%;
max-width: 16.66667%; 
}

.col-md-3 {
flex: 0 0 25%;
max-width: 25%; 
}

.col-md-4 {
flex: 0 0 33.33333%;
max-width: 33.33333%; 
}

.col-md-5 {
flex: 0 0 41.66667%;
max-width: 41.66667%; 
}

.col-md-6 {
flex: 0 0 50%;
max-width: 50%; 
}

.col-md-7 {
flex: 0 0 58.33333%;
max-width: 58.33333%; 
}

.col-md-8 {
flex: 0 0 66.66667%;
max-width: 66.66667%; 
}

.col-md-9 {
flex: 0 0 75%;
max-width: 75%; 
}

.col-md-10 {
flex: 0 0 83.33333%;
max-width: 83.33333%; 
}

.col-md-11 {
flex: 0 0 91.66667%;
max-width: 91.66667%; 
}

.col-md-12 {
flex: 0 0 100%;
max-width: 100%; 
}

.order-md-1 {
order: 1; 
}

.order-md-2 {
order: 2; 
}

.order-md-3 {
order: 3; 
}

.order-md-4 {
order: 4; 
}

.order-md-5 {
order: 5; 
}

.order-md-6 {
order: 6; 
}

.order-md-7 {
order: 7; 
}

.order-md-8 {
order: 8; 
}

.order-md-9 {
order: 9; 
}

.order-md-10 {
order: 10; 
}

.order-md-11 {
order: 11; 
}

.order-md-12 {
order: 12; 
}

}


@media (min-width: 992px) {
.col-lg {
flex-basis: 0;
flex-grow: 1;
max-width: 100%; 
}

.col-lg-auto {
flex: 0 0 auto;
width: auto;
max-width: none; 
}

.col-lg-1 {
flex: 0 0 8.33333%;
max-width: 8.33333%; 
}

.col-lg-2 {
flex: 0 0 16.66667%;
max-width: 16.66667%; 
}

.col-lg-3 {
flex: 0 0 25%;
max-width: 25%; 
}

.col-lg-4 {
flex: 0 0 33.33333%;
max-width: 33.33333%; 
}

.col-lg-5 {
flex: 0 0 41.66667%;
max-width: 41.66667%; 
}

.col-lg-6 {
flex: 0 0 50%;
max-width: 50%; 
}

.col-lg-7 {
flex: 0 0 58.33333%;
max-width: 58.33333%; 
}

.col-lg-8 {
flex: 0 0 66.66667%;
max-width: 66.66667%; 
}

.col-lg-9 {
flex: 0 0 75%;
max-width: 75%; 
}

.col-lg-10 {
flex: 0 0 83.33333%;
max-width: 83.33333%; 
}

.col-lg-11 {
flex: 0 0 91.66667%;
max-width: 91.66667%; 
}

.col-lg-12 {
flex: 0 0 100%;
max-width: 100%; 
}

.order-lg-1 {
order: 1; 
}

.order-lg-2 {
order: 2; 
}

.order-lg-3 {
order: 3; 
}

.order-lg-4 {
order: 4; 
}

.order-lg-5 {
order: 5; 
}

.order-lg-6 {
order: 6; 
}

.order-lg-7 {
order: 7; 
}

.order-lg-8 {
order: 8; 
}

.order-lg-9 {
order: 9; 
}

.order-lg-10 {
order: 10; 
}

.order-lg-11 {
order: 11; 
}

.order-lg-12 {
order: 12; 
}

}


@media (min-width: 1200px) {
.col-xl {
flex-basis: 0;
flex-grow: 1;
max-width: 100%; 
}

.col-xl-auto {
flex: 0 0 auto;
width: auto;
max-width: none; 
}

.col-xl-1 {
flex: 0 0 8.33333%;
max-width: 8.33333%; 
}

.col-xl-2 {
flex: 0 0 16.66667%;
max-width: 16.66667%; 
}

.col-xl-3 {
flex: 0 0 25%;
max-width: 25%; 
}

.col-xl-4 {
flex: 0 0 33.33333%;
max-width: 33.33333%; 
}

.col-xl-5 {
flex: 0 0 41.66667%;
max-width: 41.66667%; 
}

.col-xl-6 {
flex: 0 0 50%;
max-width: 50%; 
}

.col-xl-7 {
flex: 0 0 58.33333%;
max-width: 58.33333%; 
}

.col-xl-8 {
flex: 0 0 66.66667%;
max-width: 66.66667%; 
}

.col-xl-9 {
flex: 0 0 75%;
max-width: 75%; 
}

.col-xl-10 {
flex: 0 0 83.33333%;
max-width: 83.33333%; 
}

.col-xl-11 {
flex: 0 0 91.66667%;
max-width: 91.66667%; 
}

.col-xl-12 {
flex: 0 0 100%;
max-width: 100%; 
}

.order-xl-1 {
order: 1; 
}

.order-xl-2 {
order: 2; 
}

.order-xl-3 {
order: 3; 
}

.order-xl-4 {
order: 4; 
}

.order-xl-5 {
order: 5; 
}

.order-xl-6 {
order: 6; 
}

.order-xl-7 {
order: 7; 
}

.order-xl-8 {
order: 8; 
}

.order-xl-9 {
order: 9; 
}

.order-xl-10 {
order: 10; 
}

.order-xl-11 {
order: 11; 
}

.order-xl-12 {
order: 12; 
}

}


.table {
width: 100%;
max-width: 100%;
margin-bottom: 1rem;
background-color: transparent; 
}

.table th,
.table td {
padding: 0.75rem;
vertical-align: top;
border-top: 1px solid #eeeeee; 
}

.table thead th {
vertical-align: bottom;
border-bottom: 2px solid #eeeeee; 
}

.table tbody + tbody {
border-top: 2px solid #eeeeee; 
}

.table .table {
background-color: #fff; 
}


.table-sm th,
.table-sm td {
padding: 0.3rem; 
}


.table-bordered {
border: 1px solid #eeeeee; 
}

.table-bordered th,
.table-bordered td {
border: 1px solid #eeeeee; 
}

.table-bordered thead th,
.table-bordered thead td {
border-bottom-width: 2px; 
}


.table-striped tbody tr:nth-of-type(odd) {
background-color: rgba(0, 0, 0, 0.05); 
}


.table-hover tbody tr:hover {
background-color: rgba(0, 0, 0, 0.075); 
}


.table-primary,
.table-primary > th,
.table-primary > td {
background-color: #6873b9; 
}


.table-hover .table-primary:hover {
background-color: #5763b1; 
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
background-color: #5763b1; 
}


.table-secondary,
.table-secondary > th,
.table-secondary > td {
background-color: #9f5bbb; 
}


.table-hover .table-secondary:hover {
background-color: #944ab2; 
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
background-color: #944ab2; 
}


.table-success,
.table-success > th,
.table-success > td {
background-color: #6ead71; 
}


.table-hover .table-success:hover {
background-color: #5ea461; 
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
background-color: #5ea461; 
}


.table-info,
.table-info > th,
.table-info > td {
background-color: #46a8dd; 
}


.table-hover .table-info:hover {
background-color: #309ed9; 
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
background-color: #309ed9; 
}


.table-warning,
.table-warning > th,
.table-warning > td {
background-color: #ffba45; 
}


.table-hover .table-warning:hover {
background-color: #ffb12c; 
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
background-color: #ffb12c; 
}


.table-danger,
.table-danger > th,
.table-danger > td {
background-color: #df6767; 
}


.table-hover .table-danger:hover {
background-color: #db5252; 
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
background-color: #db5252; 
}


.table-light,
.table-light > th,
.table-light > td {
background-color: #f8f8f8; 
}


.table-hover .table-light:hover {
background-color: #ebebeb; 
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
background-color: #ebebeb; 
}


.table-dark,
.table-dark > th,
.table-dark > td {
background-color: #5d5d5d; 
}


.table-hover .table-dark:hover {
background-color: #505050; 
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
background-color: #505050; 
}


.table-active,
.table-active > th,
.table-active > td {
background-color: rgba(0, 0, 0, 0.075); 
}


.table-hover .table-active:hover {
background-color: rgba(0, 0, 0, 0.075); 
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
background-color: rgba(0, 0, 0, 0.075); 
}


.thead-inverse th {
color: #fff;
background-color: #212121; 
}


.thead-default th {
color: #616161;
background-color: #eeeeee; 
}


.table-inverse {
color: #fff;
background-color: #212121; 
}

.table-inverse th,
.table-inverse td,
.table-inverse thead th {
border-color: #343434; 
}

.table-inverse.table-bordered {
border: 0; 
}

.table-inverse.table-striped tbody tr:nth-of-type(odd) {
background-color: rgba(255, 255, 255, 0.05); 
}

.table-inverse.table-hover tbody tr:hover {
background-color: rgba(255, 255, 255, 0.075); 
}


@media (max-width: 991px) {
.table-responsive {
display: block;
width: 100%;
overflow-x: auto;
-ms-overflow-style: -ms-autohiding-scrollbar; 
}

.table-responsive.table-bordered {
border: 0; 
}

}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #616161;
  /* background-color: #fff; */
  background-color: #E5E5E5;
  background-image: none;
  background-clip: padding-box;
  /* border: 1px solid rgba(0, 0, 0, 0.15); */

}

.form-control::-ms-expand {
background-color: transparent;
border: 0; 
}

.form-control:focus {
  color: #616161;
  background-color: #fff;
  border-color: #7885d6;
  outline: none; 
}

.form-control::placeholder {
  color: #757575;
  opacity: 1; 
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eeeeee;
  opacity: 1; 
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); 
}

select.form-control:focus::-ms-value {
  color: #616161;
  background-color: #fff; 
}

.form-control-file,
.form-control-range {
  display: block; 
}

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc( 0.5rem - 1px * 2);
  margin-bottom: 0; 
}

.col-form-label-lg {
  padding-top: calc( 0.5rem - 1px * 2);
  padding-bottom: calc( 0.5rem - 1px * 2);
  font-size: 1.25rem; 
}

.col-form-label-sm {
  padding-top: calc( 0.25rem - 1px * 2);
  padding-bottom: calc( 0.25rem - 1px * 2);
  font-size: 0.875rem; 
}

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem; 
}

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; 
}

.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0; 
}


.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; 
}


select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); 
}


.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; 
}


select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); 
}


.form-group {
  margin-bottom: 1rem; 
}


.form-text {
  display: block;
  margin-top: 0.25rem; 
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; 
}

.form-row > .col,
.form-row > [class*="col-"] {
padding-right: 5px;
padding-left: 5px; 
}


.form-check {
position: relative;
display: block;
margin-bottom: 0.5rem; 
}

.form-check.disabled .form-check-label {
color: #757575; 
}


.form-check-label {
padding-left: 1.25rem;
margin-bottom: 0; 
}


.form-check-input {
position: absolute;
margin-top: 0.25rem;
margin-left: -1.25rem; 
}


.form-check-inline {
display: inline-block; 
}

.form-check-inline .form-check-label {
vertical-align: middle; 
}

.form-check-inline + .form-check-inline {
margin-left: 0.75rem; 
}


.invalid-feedback {
display: none;
margin-top: 0.25rem;
font-size: 0.875rem;
color: #d32f2f; 
}


.invalid-tooltip {
position: absolute;
top: 100%;
z-index: 5;
display: none;
width: 250px;
padding: 0.5rem;
margin-top: 0.1rem;
font-size: 0.875rem;
line-height: 1;
color: #fff;
background-color: rgba(211, 47, 47, 0.8);
border-radius: 0.2rem; 
}


.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #388e3c; 
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
box-shadow: 0 0 0 0.2rem rgba(56, 142, 60, 0.25); 
}

.was-validated .form-control:valid ~ .invalid-feedback,
.was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
.form-control.is-valid ~ .invalid-tooltip, .was-validated
.custom-select:valid ~ .invalid-feedback,
.was-validated
.custom-select:valid ~ .invalid-tooltip,
.custom-select.is-valid ~ .invalid-feedback,
.custom-select.is-valid ~ .invalid-tooltip {
display: block; 
}


.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
color: #388e3c; 
}


.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
background-color: rgba(56, 142, 60, 0.25); 
}


.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
color: #388e3c; 
}


.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
border-color: #388e3c; 
}

.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
border-color: inherit; 
}


.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
box-shadow: 0 0 0 0.2rem rgba(56, 142, 60, 0.25); 
}


.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #d32f2f !important; 
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.25); 
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block; 
}


.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
color: #d32f2f; 
}


.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
background-color: rgba(211, 47, 47, 0.25); 
}


.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
color: #d32f2f; 
}


.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
border-color: #d32f2f; 
}

.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
border-color: inherit; 
}


.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.25); 
}


.form-inline {
display: flex;
flex-flow: row wrap;
align-items: center; 
}

.form-inline .form-check {
width: 100%; 
}

@media (min-width: 576px) {
.form-inline label {
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 0; 
}


.form-inline .form-group {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 0; 
}


.form-inline .form-control {
display: inline-block;
width: auto;
vertical-align: middle; 
}

.form-inline .form-control-plaintext {
display: inline-block; 
}

.form-inline .input-group {
width: auto; 
}

.form-inline .form-control-label {
margin-bottom: 0;
vertical-align: middle; 
}

.form-inline .form-check {
display: flex;
align-items: center;
justify-content: center;
width: auto;
margin-top: 0;
margin-bottom: 0; 
}

.form-inline .form-check-label {
padding-left: 0; 
}

.form-inline .form-check-input {
position: relative;
margin-top: 0;
margin-right: 0.25rem;
margin-left: 0; 
}

.form-inline .custom-control {
display: flex;
align-items: center;
justify-content: center;
padding-left: 0; 
}

.form-inline .custom-control-indicator {
position: static;
display: inline-block;
margin-right: 0.25rem;
vertical-align: text-bottom; 
}

.form-inline .has-feedback .form-control-feedback {
top: 0; 
}

}


/* .btn {
display: inline-block;
font-weight: normal;
text-align: center;
white-space: nowrap;
vertical-align: middle;
user-select: none;
border: 1px solid transparent;
padding: 0.5rem 0.75rem;
font-size: 1rem;
line-height: 1.25; 
} */

/* .btn:focus, .btn:hover {
text-decoration: none; 
} */

/* .btn:focus, .btn.focus {
outline: 0;
box-shadow: 0 0 0 3px rgba(48, 63, 159, 0.25); 
}

.btn.disabled, .btn:disabled {
opacity: 0.65; 
}

.btn:active, .btn.active {
background-image: none; 
}


a.btn.disabled,
fieldset[disabled] a.btn {
pointer-events: none; 
} */


/* .btn-primary {
color: #fff;
background-color: #303f9f;
border-color: #303f9f; 
}

.btn-primary:hover {
color: #fff;
background-color: #273382;
border-color: #242f78; 
}

.btn-primary:focus, .btn-primary.focus {
box-shadow: 0 0 0 3px rgba(48, 63, 159, 0.5); 
}

.btn-primary.disabled, .btn-primary:disabled {
background-color: #303f9f;
border-color: #303f9f; 
}

.btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
background-color: #273382;
background-image: none;
border-color: #242f78; 
}


.btn-secondary {
color: #fff;
background-color: #7b1fa2;
border-color: #7b1fa2; 
}

.btn-secondary:hover {
color: #fff;
background-color: #631982;
border-color: #5a1777; 
}

.btn-secondary:focus, .btn-secondary.focus {
box-shadow: 0 0 0 3px rgba(123, 31, 162, 0.5); 
}

.btn-secondary.disabled, .btn-secondary:disabled {
background-color: #7b1fa2;
border-color: #7b1fa2; 
}

.btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
background-color: #631982;
background-image: none;
border-color: #5a1777; 
} */


/* .btn-success {
  color: #fff !important;
  font-family: Poppins;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 24px;
  background-color: #79C047;
  border-color: #79C047; 
} */

/* .btn-success:hover {
  color: #fff;
  background-color: #53883D;
  border-color: #53883D; 
} */

/* .btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 3px rgba(56, 142, 60, 0.5); 
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #388e3c;
  border-color: #388e3c; 
}

.btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #2d7330;
  background-image: none;
  border-color: #2a692d; 
} */


/* .btn-info {
color: #fff;
background-color: #0288d1;
border-color: #0288d1; 
}

.btn-info:hover {
color: #fff;
background-color: #026fab;
border-color: #02679e; 
}

.btn-info:focus, .btn-info.focus {
box-shadow: 0 0 0 3px rgba(2, 136, 209, 0.5); 
}

.btn-info.disabled, .btn-info:disabled {
background-color: #0288d1;
border-color: #0288d1; 
} */
/* 
.btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
background-color: #026fab;
background-image: none;
border-color: #02679e; 
}


.btn-warning {
color: #111;
background-color: #ffa000;
border-color: #ffa000; 
}

.btn-warning:hover {
color: #111;
background-color: #d98800;
border-color: #cc8000; 
}

.btn-warning:focus, .btn-warning.focus {
box-shadow: 0 0 0 3px rgba(255, 160, 0, 0.5); 
} */

.btn-warning.disabled, .btn-warning:disabled {
background-color: #ffa000;
border-color: #ffa000; 
}

/* .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
background-color: #d98800;
background-image: none;
border-color: #cc8000; 
}


.btn-danger {
color: #fff;
background-color: #d32f2f;
border-color: #d32f2f; 
}

.btn-danger:hover {
color: #fff;
background-color: #b52626;
border-color: #ab2424; 
} */
/* 
.btn-danger:focus, .btn-danger.focus {
box-shadow: 0 0 0 3px rgba(211, 47, 47, 0.5); 
}

.btn-danger.disabled, .btn-danger:disabled {
background-color: #d32f2f;
border-color: #d32f2f; 
}

.btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
background-color: #b52626;
background-image: none;
border-color: #ab2424; 
}
 */
/* 
.btn-light {
color: #111;
background-color: #f5f5f5;
border-color: #f5f5f5; 
}

.btn-light:hover {
color: #111;
background-color: #e2e2e2;
border-color: gainsboro; 
}

.btn-light:focus, .btn-light.focus {
box-shadow: 0 0 0 3px rgba(245, 245, 245, 0.5); 
}

.btn-light.disabled, .btn-light:disabled {
background-color: #f5f5f5;
border-color: #f5f5f5; 
}

.btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
background-color: #e2e2e2;
background-image: none;
border-color: gainsboro; 
} */

/* 
.btn-dark {
color: #fff;
background-color: #212121;
border-color: #212121; 
}

.btn-dark:hover {
color: #fff;
background-color: #0e0e0e;
border-color: #080808; 
}

.btn-dark:focus, .btn-dark.focus {
box-shadow: 0 0 0 3px rgba(33, 33, 33, 0.5); 
}

.btn-dark.disabled, .btn-dark:disabled {
background-color: #212121;
border-color: #212121; 
}

.btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
background-color: #0e0e0e;
background-image: none;
border-color: #080808; 
}


.btn-outline-primary {
color: #303f9f;
background-color: transparent;
background-image: none;
border-color: #303f9f; 
}

.btn-outline-primary:hover {
color: #fff;
background-color: #303f9f;
border-color: #303f9f; 
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
box-shadow: 0 0 0 3px rgba(48, 63, 159, 0.5); 
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
color: #303f9f;
background-color: transparent; 
}

.btn-outline-primary:active, .btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
color: #fff;
background-color: #303f9f;
border-color: #303f9f; 
}


.btn-outline-secondary {
color: #7b1fa2;
background-color: transparent;
background-image: none;
border-color: #7b1fa2; 
} */
/* 
.btn-outline-secondary:hover {
color: #fff;
background-color: #7b1fa2;
border-color: #7b1fa2; 
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
box-shadow: 0 0 0 3px rgba(123, 31, 162, 0.5); 
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
color: #7b1fa2;
background-color: transparent; 
}

.btn-outline-secondary:active, .btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
color: #fff;
background-color: #7b1fa2;
border-color: #7b1fa2; 
}


.btn-outline-success {
color: #388e3c;
background-color: transparent;
background-image: none;
border-color: #388e3c; 
}

.btn-outline-success:hover {
color: #fff;
background-color: #388e3c;
border-color: #388e3c; 
}

.btn-outline-success:focus, .btn-outline-success.focus {
box-shadow: 0 0 0 3px rgba(56, 142, 60, 0.5); 
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
color: #388e3c;
background-color: transparent; 
}

.btn-outline-success:active, .btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
color: #fff;
background-color: #388e3c;
border-color: #388e3c; 
}


.btn-outline-info {
color: #0288d1;
background-color: transparent;
background-image: none;
border-color: #0288d1; 
}

.btn-outline-info:hover {
color: #fff;
background-color: #0288d1;
border-color: #0288d1; 
}

.btn-outline-info:focus, .btn-outline-info.focus {
box-shadow: 0 0 0 3px rgba(2, 136, 209, 0.5); 
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
color: #0288d1;
background-color: transparent; 
}

.btn-outline-info:active, .btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
color: #fff;
background-color: #0288d1;
border-color: #0288d1; 
}


.btn-outline-warning {
color: #ffa000;
background-color: transparent;
background-image: none;
border-color: #ffa000; 
}

.btn-outline-warning:hover {
color: #fff;
background-color: #ffa000;
border-color: #ffa000; 
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
box-shadow: 0 0 0 3px rgba(255, 160, 0, 0.5); 
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
color: #ffa000;
background-color: transparent; 
}

.btn-outline-warning:active, .btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
color: #fff;
background-color: #ffa000;
border-color: #ffa000; 
}


.btn-outline-danger {
color: #d32f2f;
background-color: transparent;
background-image: none;
border-color: #d32f2f; 
}

.btn-outline-danger:hover {
color: #fff;
background-color: #d32f2f;
border-color: #d32f2f; 
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
box-shadow: 0 0 0 3px rgba(211, 47, 47, 0.5); 
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
color: #d32f2f;
background-color: transparent; 
}

.btn-outline-danger:active, .btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
color: #fff;
background-color: #d32f2f;
border-color: #d32f2f; 
}


.btn-outline-light {
color: #f5f5f5;
background-color: transparent;
background-image: none;
border-color: #f5f5f5; 
}

.btn-outline-light:hover {
color: #fff;
background-color: #f5f5f5;
border-color: #f5f5f5; 
}

.btn-outline-light:focus, .btn-outline-light.focus {
box-shadow: 0 0 0 3px rgba(245, 245, 245, 0.5); 
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
color: #f5f5f5;
background-color: transparent; 
}

.btn-outline-light:active, .btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
color: #fff;
background-color: #f5f5f5;
border-color: #f5f5f5; 
}


.btn-outline-dark {
color: #212121;
background-color: transparent;
background-image: none;
border-color: #212121; 
}

.btn-outline-dark:hover {
color: #fff;
background-color: #212121;
border-color: #212121; 
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
box-shadow: 0 0 0 3px rgba(33, 33, 33, 0.5); 
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
color: #212121;
background-color: transparent; 
}

.btn-outline-dark:active, .btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
color: #fff;
background-color: #212121;
border-color: #212121; 
}


.btn-link {
font-weight: normal;
color: #303f9f;
border-radius: 0; 
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
background-color: transparent; 
}

.btn-link, .btn-link:focus, .btn-link:active {
border-color: transparent;
box-shadow: none; 
}

.btn-link:hover {
border-color: transparent; 
}

.btn-link:focus, .btn-link:hover {
color: #1e2864;
text-decoration: underline;
background-color: transparent; 
}

.btn-link:disabled {
color: #757575; 
}

.btn-link:disabled:focus, .btn-link:disabled:hover {
text-decoration: none; 
}


.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5; 
}


.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important; 
}

.btn-block {
  display: block;
  width: 100%; 
}

.btn-block + .btn-block {
margin-top: 0.5rem; 
} */

input[type="submit"].btn-block,
input[type="file"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
width: 100%; 
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important; 
}

.fade {
opacity: 0; 
}

.fade.show {
opacity: 1; 
}


.collapse {
display: none; 
}

.collapse.show {
display: block; 
}


tr.collapse.show {
display: table-row; 
}


tbody.collapse.show {
display: table-row-group; 
}


.collapsing {
position: relative;
height: 0;
overflow: hidden; 
}


.dropup,
.dropdown {
position: relative; 
}


.dropdown-toggle::after {
display: inline-block;
width: 0;
height: 0;
margin-left: 0.255em;
vertical-align: 0.255em;
content: "";
border-top: 0.3em solid;
border-right: 0.3em solid transparent;
border-left: 0.3em solid transparent; 
}


.dropdown-toggle:empty::after {
margin-left: 0; 
}


.dropup .dropdown-menu {
margin-top: 0;
margin-bottom: 0.125rem; 
}


.dropup .dropdown-toggle::after {
border-top: 0;
border-bottom: 0.3em solid; 
}


.dropdown-menu {
position: absolute;
top: 100%;
left: 0;
z-index: 1000;
display: none;
float: left;
min-width: 10rem;
padding: 0.5rem 0;
margin: 0.125rem 0 0;
font-size: 1rem;
color: #212121;
text-align: left;
list-style: none;
background-color: #fff;
background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.15); 
}


.dropdown-divider {
height: 0;
margin: 0.5rem 0;
overflow: hidden;
border-top: 1px solid #eeeeee; 
}


.dropdown-item {
display: block;
width: 100%;
padding: 0.25rem 1.5rem;
clear: both;
font-weight: normal;
color: #212121;
text-align: inherit;
white-space: nowrap;
background: none;
border: 0; 
}

.dropdown-item:focus, .dropdown-item:hover {
color: #141414;
text-decoration: none;
background-color: #f5f5f5; 
}

.dropdown-item.active, .dropdown-item:active {
color: #fff;
text-decoration: none;
background-color: #303f9f; 
}

.dropdown-item.disabled, .dropdown-item:disabled {
color: #757575;
background-color: transparent; 
}


.show > a {
outline: 0; 
}


.dropdown-menu.show {
display: block; 
}


.dropdown-header {
display: block;
padding: 0.5rem 1.5rem;
margin-bottom: 0;
font-size: 0.875rem;
color: #757575;
white-space: nowrap; 
}


.btn-group,
.btn-group-vertical {
position: relative;
display: inline-flex;
vertical-align: middle; 
}

.btn-group > .btn,
.btn-group-vertical > .btn {
position: relative;
flex: 0 1 auto;
margin-bottom: 0; 
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
z-index: 2; 
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
z-index: 2; 
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
margin-left: -1px; 
}


.btn-toolbar {
display: flex;
flex-wrap: wrap;
justify-content: flex-start; 
}

.btn-toolbar .input-group {
width: auto; 
}


.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
border-radius: 0; 
}


.btn-group > .btn:first-child {
margin-left: 0; 
}


.btn-group > .btn-group {
float: left; 
}


.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
border-radius: 0; 
}


.btn + .dropdown-toggle-split {
padding-right: 0.5625rem;
padding-left: 0.5625rem; 
}

.btn + .dropdown-toggle-split::after {
margin-left: 0; 
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important; 
}


.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
padding-right: 0.75rem;
padding-left: 0.75rem; 
}


.btn-group-vertical {
display: inline-flex;
flex-direction: column;
align-items: flex-start;
justify-content: center; 
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
width: 100%; 
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
margin-top: -1px;
margin-left: 0; 
}


.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
border-radius: 0; 
}


.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
border-radius: 0; 
}


[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="file"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; 
}


.input-group {
position: relative;
display: flex;
width: 100%; 
}

.input-group .form-control {
position: relative;
z-index: 2;
flex: 1 1 auto;
width: 1%;
margin-bottom: 0; 
}

.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
z-index: 3; 
}


.input-group-addon,
.input-group-btn,
.input-group .form-control {
display: flex;
align-items: center; 
}


.input-group-addon,
.input-group-btn {
white-space: nowrap;
vertical-align: middle; 
}


.input-group-addon {
/* padding: 0.5rem 0.75rem; */
margin-bottom: 0;
font-size: 1rem;
font-weight: normal;
line-height: 1.25;
color: #616161;
text-align: center;
background-color: #eeeeee;
background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.15); 
}

.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
padding: 0.25rem 0.5rem;
font-size: 0.875rem; 
}

.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
padding: 0.5rem 1rem;
font-size: 1.25rem; 
}

.input-group-addon input[type="radio"],
.input-group-addon input[type="checkbox"] {
margin-top: 0; 
}


.input-group-addon:not(:last-child) {
border-right: 0; }

.form-control + .input-group-addon:not(:first-child) {
border-left: 0; }

.input-group-btn {
position: relative;
font-size: 0;
white-space: nowrap; }
.input-group-btn > .btn {
position: relative;
background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.15); }
.input-group-btn > .btn + .btn {
margin-left: -1px; }
.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
z-index: 3; }
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
margin-right: -1px; }
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
z-index: 2;
margin-left: -1px; }
.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
z-index: 3; }

.custom-control {
position: relative;
display: inline-flex;
min-height: 1.5rem;
padding-left: 1.5rem;
margin-right: 1rem; }

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-color: #388e3c;
  border-radius: 5px;
}

.custom-control-input {
position: absolute;
z-index: -1;
opacity: 0; }
.custom-control-input:checked ~ .custom-control-indicator {
color: #fff;
background-color: #388e3c; 
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #388e3c;
  background-color: #388e3c;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #388e3c; 
}
.custom-control-input:active ~ .custom-control-indicator {
color: #fff;
background-color: #9fa8e2; }

.custom-control-input:disabled  {
background-color: #eeeeee; 
}
.custom-control-input:disabled ~ .custom-control-description {
color: #757575; 
}

.custom-control-indicator {
position: absolute;
top: 0.25rem;
left: 0;
display: block;
width: 1rem;
height: 1rem;
pointer-events: none;
user-select: none;
background-color: #ddd;
background-repeat: no-repeat;
background-position: center center;
background-size: 50% 50%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
background-color: #388e3c; 
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
display: flex;
flex-direction: column; }
.custom-controls-stacked .custom-control {
margin-bottom: 0.25rem; }
.custom-controls-stacked .custom-control + .custom-control {
margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #616161;
  vertical-align: middle;
  background-color: #F9F9F9 !important;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-clip: padding-box;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  appearance: none; 
}

.custom-select:focus {
  border-color: #7885d6;
  outline: none; 
}

.custom-select:focus::-ms-value {
  color: #616161;
  background-color: #fff; 
  
}

.custom-select:disabled {
  color: #757575;
  background-color: #eeeeee; 
}

.custom-select::-ms-expand {
  opacity: 0; 
}


.custom-select-sm {
height: calc(1.8125rem + 2px);
padding-top: 0.375rem;
padding-bottom: 0.375rem;
font-size: 75%; 
}


.custom-file {
position: relative;
display: inline-block;
max-width: 100%;
height: calc(2.25rem + 2px);
margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; 
}

.custom-file-input:focus ~ .custom-file-control {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #303f9f; 
}

.custom-file-control {
position: absolute;
top: 0;
right: 0;
left: 0;
z-index: 5;
height: calc(2.25rem + 2px);
padding: 0.5rem 0.75rem;
overflow: hidden;
line-height: 1.25;
color: #616161;
pointer-events: none;
user-select: none;
background-color: #fff;
background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.15); }
.custom-file-control:lang(en):empty::after {
content: "Choose file..."; }
.custom-file-control::before {
position: absolute;
top: 0;
right: 0;
z-index: 6;
display: block;
height: calc(2.25rem + 2px);
padding: 0.5rem 0.75rem;
line-height: 1.25;
color: #616161;
background-color: #eeeeee;
border-left: 1px solid rgba(0, 0, 0, 0.15); }
.custom-file-control:lang(en)::before {
content: "Browse"; }

.nav {
display: flex;
flex-wrap: wrap;
padding-left: 0;
margin-bottom: 0;
list-style: none; }

.nav-link {
display: block;
padding: 0.5rem 1rem; }
.nav-link:focus, .nav-link:hover {
text-decoration: none; }
.nav-link.disabled {
color: #757575; }

.nav-tabs {
border-bottom: 1px solid #ddd; }
.nav-tabs .nav-item {
margin-bottom: -1px; }
.nav-tabs .nav-link {
border: 1px solid transparent; }
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
border-color: #eeeeee #eeeeee #ddd; }
.nav-tabs .nav-link.disabled {
color: #757575;
background-color: transparent;
border-color: transparent; }
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
color: #616161;
background-color: #fff;
border-color: #ddd #ddd #fff; }
.nav-tabs .dropdown-menu {
margin-top: -1px; }

.nav-pills .nav-link.active,
.show > .nav-pills .nav-link {
color: #fff;
background-color: #303f9f; }

.nav-fill .nav-item {
flex: 1 1 auto;
text-align: center; }

.nav-justified .nav-item {
flex-basis: 0;
flex-grow: 1;
text-align: center; }

.tab-content > .tab-pane {
display: none; }

.tab-content > .active {
display: block; }

.navbar {
position: relative;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
padding: 0.5rem 1rem; }
.navbar > .container,
.navbar > .container-fluid {
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: space-between; }

.navbar-brand {
display: inline-block;
padding-top: 0.3125rem;
padding-bottom: 0.3125rem;
margin-right: 1rem;
font-size: 1.25rem;
line-height: inherit;
white-space: nowrap; }
.navbar-brand:focus, .navbar-brand:hover {
text-decoration: none; }

.navbar-nav {
display: flex;
flex-direction: column;
padding-left: 0;
margin-bottom: 0;
list-style: none; }
.navbar-nav .nav-link {
padding-right: 0;
padding-left: 0; }
.navbar-nav .dropdown-menu {
position: static;
float: none; }

.navbar-text {
display: inline-block;
padding-top: 0.5rem;
padding-bottom: 0.5rem; }

.navbar-collapse {
flex-basis: 100%;
align-items: center; }

.navbar-toggler {
padding: 0.25rem 0.75rem;
font-size: 1.25rem;
line-height: 1;
background: transparent;
border: 1px solid transparent; }
.navbar-toggler:focus, .navbar-toggler:hover {
text-decoration: none; }

.navbar-toggler-icon {
display: inline-block;
width: 1.5em;
height: 1.5em;
vertical-align: middle;
content: "";
background: no-repeat center center;
background-size: 100% 100%; }

@media (max-width: 575px) {
.navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
padding-right: 0;
padding-left: 0; } }

@media (min-width: 576px) {
.navbar-expand-sm {
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start; }
.navbar-expand-sm .navbar-nav {
flex-direction: row; }
.navbar-expand-sm .navbar-nav .dropdown-menu {
position: absolute; }
.navbar-expand-sm .navbar-nav .dropdown-menu-right {
right: 0;
left: auto; }
.navbar-expand-sm .navbar-nav .nav-link {
padding-right: 0.5rem;
padding-left: 0.5rem; }
.navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
flex-wrap: nowrap; }
.navbar-expand-sm .navbar-collapse {
display: flex !important; }
.navbar-expand-sm .navbar-toggler {
display: none; } }

@media (max-width: 767px) {
.navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
padding-right: 0;
padding-left: 0; } }

@media (min-width: 768px) {
.navbar-expand-md {
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start; }
.navbar-expand-md .navbar-nav {
flex-direction: row; }
.navbar-expand-md .navbar-nav .dropdown-menu {
position: absolute; }
.navbar-expand-md .navbar-nav .dropdown-menu-right {
right: 0;
left: auto; }
.navbar-expand-md .navbar-nav .nav-link {
padding-right: 0.5rem;
padding-left: 0.5rem; }
.navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
flex-wrap: nowrap; }
.navbar-expand-md .navbar-collapse {
display: flex !important; }
.navbar-expand-md .navbar-toggler {
display: none; } }

@media (max-width: 991px) {
.navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
padding-right: 0;
padding-left: 0; } }

@media (min-width: 992px) {
.navbar-expand-lg {
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start; }
.navbar-expand-lg .navbar-nav {
flex-direction: row; }
.navbar-expand-lg .navbar-nav .dropdown-menu {
position: absolute; }
.navbar-expand-lg .navbar-nav .dropdown-menu-right {
right: 0;
left: auto; }
.navbar-expand-lg .navbar-nav .nav-link {
padding-right: 0.5rem;
padding-left: 0.5rem; }
.navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
flex-wrap: nowrap; }
.navbar-expand-lg .navbar-collapse {
display: flex !important; }
.navbar-expand-lg .navbar-toggler {
display: none; } }

@media (max-width: 1199px) {
.navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
padding-right: 0;
padding-left: 0; } }

@media (min-width: 1200px) {
.navbar-expand-xl {
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start; }
.navbar-expand-xl .navbar-nav {
flex-direction: row; }
.navbar-expand-xl .navbar-nav .dropdown-menu {
position: absolute; }
.navbar-expand-xl .navbar-nav .dropdown-menu-right {
right: 0;
left: auto; }
.navbar-expand-xl .navbar-nav .nav-link {
padding-right: 0.5rem;
padding-left: 0.5rem; }
.navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
flex-wrap: nowrap; }
.navbar-expand-xl .navbar-collapse {
display: flex !important; }
.navbar-expand-xl .navbar-toggler {
display: none; } }

.navbar-expand {
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start; }
.navbar-expand > .container,
.navbar-expand > .container-fluid {
padding-right: 0;
padding-left: 0; }
.navbar-expand .navbar-nav {
flex-direction: row; }
.navbar-expand .navbar-nav .dropdown-menu {
position: absolute; }
.navbar-expand .navbar-nav .dropdown-menu-right {
right: 0;
left: auto; }
.navbar-expand .navbar-nav .nav-link {
padding-right: 0.5rem;
padding-left: 0.5rem; }
.navbar-expand > .container,
.navbar-expand > .container-fluid {
flex-wrap: nowrap; }
.navbar-expand .navbar-collapse {
display: flex !important; }
.navbar-expand .navbar-toggler {
display: none; }

.navbar-light .navbar-brand {
color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
color: rgba(0, 0, 0, 0.5); }
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
color: rgba(0, 0, 0, 0.7); }
.navbar-light .navbar-nav .nav-link.disabled {
color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
color: rgba(0, 0, 0, 0.5);
border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
color: rgba(0, 0, 0, 0.5); }

.navbar-dark .navbar-brand {
color: white; }
.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
color: white; }

.navbar-dark .navbar-nav .nav-link {
color: rgba(255, 255, 255, 0.5); }
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
color: rgba(255, 255, 255, 0.75); }
.navbar-dark .navbar-nav .nav-link.disabled {
color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
color: white; }

.navbar-dark .navbar-toggler {
color: rgba(255, 255, 255, 0.5);
border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
color: rgba(255, 255, 255, 0.5); }

.card {
position: relative;
display: flex;
flex-direction: column;
min-width: 0;
word-wrap: break-word;
background-color: #fff;
background-clip: border-box;
border: 1px solid rgba(0, 0, 0, 0.125); }

.card-body {
flex: 1 1 auto;
padding: 1.25rem; }

.card-title {
margin-bottom: 0.75rem; }

.card-subtitle {
margin-top: -0.375rem;
margin-bottom: 0; }

.card-text:last-child {
margin-bottom: 0; }

.card-link:hover {
text-decoration: none; }

.card-link + .card-link {
margin-left: 1.25rem; }

.card-header {
padding: 0.75rem 1.25rem;
margin-bottom: 0;
background-color: rgba(0, 0, 0, 0.03);
border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer {
padding: 0.75rem 1.25rem;
background-color: rgba(0, 0, 0, 0.03);
border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
margin-right: -0.625rem;
margin-bottom: -0.75rem;
margin-left: -0.625rem;
border-bottom: 0; }

.card-header-pills {
margin-right: -0.625rem;
margin-left: -0.625rem; }

.card-img-overlay {
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
padding: 1.25rem; }

.card-img {
width: 100%; }

.card-img-top {
width: 100%; }

.card-img-bottom {
width: 100%; }

@media (min-width: 576px) {
.card-deck {
display: flex;
flex-flow: row wrap;
margin-right: -10px;
margin-left: -10px; }
.card-deck .card {
display: flex;
flex: 1 0 0%;
flex-direction: column;
margin-right: 10px;
margin-left: 10px; } }

@media (min-width: 576px) {
.card-group {
display: flex;
flex-flow: row wrap; }
.card-group .card {
flex: 1 0 0%; }
.card-group .card + .card {
margin-left: 0;
border-left: 0; } }

.card-columns .card {
margin-bottom: 0.75rem; }

@media (min-width: 576px) {
.card-columns {
column-count: 3;
column-gap: 1.25rem; }
.card-columns .card {
display: inline-block;
width: 100%; } }

.breadcrumb {
padding: 0.75rem 1rem;
margin-bottom: 1rem;
list-style: none;
background-color: #eeeeee; }
.breadcrumb::after {
display: block;
clear: both;
content: ""; }

.breadcrumb-item {
float: left; }
.breadcrumb-item + .breadcrumb-item::before {
display: inline-block;
padding-right: 0.5rem;
padding-left: 0.5rem;
color: #757575;
content: "/"; }
.breadcrumb-item + .breadcrumb-item:hover::before {
text-decoration: underline; }
.breadcrumb-item + .breadcrumb-item:hover::before {
text-decoration: none; }
.breadcrumb-item.active {
color: #757575; }

.pagination {
display: flex;
padding-left: 0;
list-style: none;}

/* react table component */
.rdt_Table {
  height: auto!important;
  margin-top: -30px;
}

.rdt_TableCell div{
  font-size: 13px !important;
  padding: 0 !important;
}

.eDAjEh {
  min-height: 20px !important;
}

.rdt_TableCol_Sortable div{
  font-size: 16px !important;
}

.rdt_TableHeader div {
  margin-left: -15px !important;
  font-size: 16px !important;
  color: #4D4D4D;
  font-weight: bold;
}

.iBFjFB {
  min-height: 0;
}
/* react table component */

.page-item:first-child .page-link {
margin-left: 0; }

.page-item.active .page-link {
z-index: 2;
color: #fff;
background-color: #303f9f;
border-color: #303f9f; }

.page-item.disabled .page-link {
color: #757575;
pointer-events: none;
background-color: #fff;
border-color: #ddd; }

.page-link {
position: relative;
display: block;
padding: 0.5rem 0.75rem;
margin-left: -1px;
line-height: 1.25;
color: #303f9f;
background-color: #fff;
border: 1px solid #ddd; }
.page-link:focus, .page-link:hover {
color: #1e2864;
text-decoration: none;
background-color: #eeeeee;
border-color: #ddd; }

.pagination-lg .page-link {
padding: 0.75rem 1.5rem;
font-size: 1.25rem;
line-height: 1.5; }

.pagination-sm .page-link {
padding: 0.25rem 0.5rem;
font-size: 0.875rem;
line-height: 1.5; }

.badge {
display: inline-block;
padding: 0.25em 0.4em;
font-size: 75%;
font-weight: bold;
line-height: 1;
color: #fff;
text-align: center;
white-space: nowrap;
vertical-align: baseline; }
.badge:empty {
display: none; }

.btn .badge {
position: relative;
top: -1px; }

.badge-pill {
padding-right: 0.6em;
padding-left: 0.6em; }

.badge-primary {
color: #fff;
background-color: #303f9f; }
.badge-primary[href]:focus, .badge-primary[href]:hover {
color: #fff;
text-decoration: none;
background-color: #242f78; }

.badge-secondary {
color: #fff;
background-color: #7b1fa2; }
.badge-secondary[href]:focus, .badge-secondary[href]:hover {
color: #fff;
text-decoration: none;
background-color: #5a1777; }

.badge-success {
color: #fff;
background-color: #388e3c; }
.badge-success[href]:focus, .badge-success[href]:hover {
color: #fff;
text-decoration: none;
background-color: #2a692d; }

.badge-info {
color: #fff;
background-color: #0288d1; }
.badge-info[href]:focus, .badge-info[href]:hover {
color: #fff;
text-decoration: none;
background-color: #02679e; }

.badge-warning {
color: #111;
background-color: #ffa000; }
.badge-warning[href]:focus, .badge-warning[href]:hover {
color: #111;
text-decoration: none;
background-color: #cc8000; }

.badge-danger {
color: #fff;
background-color: #d32f2f; }
.badge-danger[href]:focus, .badge-danger[href]:hover {
color: #fff;
text-decoration: none;
background-color: #ab2424; }

.badge-light {
color: #111;
background-color: #f5f5f5; }
.badge-light[href]:focus, .badge-light[href]:hover {
color: #111;
text-decoration: none;
background-color: gainsboro; }

.badge-dark {
color: #fff;
background-color: #212121; }
.badge-dark[href]:focus, .badge-dark[href]:hover {
color: #fff;
text-decoration: none;
background-color: #080808; }

.jumbotron {
padding: 2rem 1rem;
margin-bottom: 2rem;
background-color: #eeeeee; }
@media (min-width: 576px) {
.jumbotron {
padding: 4rem 2rem; } }

.jumbotron-fluid {
padding-right: 0;
padding-left: 0; }

.alert {
padding: 0.75rem 1.25rem;
margin-bottom: 1rem;
border: 1px solid transparent; }

.alert-heading {
color: inherit; }

.alert-link {
font-weight: bold; }

.alert-dismissible .close {
position: relative;
top: -0.75rem;
right: -1.25rem;
padding: 0.75rem 1.25rem;
color: inherit; }

.alert-primary {
color: #273482;
background-color: #6e79bc;
border-color: #6873b9; }
.alert-primary hr {
border-top-color: #5763b1; }
.alert-primary .alert-link {
color: #1b245b; }

.alert-secondary {
color: #651985;
background-color: #a362be;
border-color: #9f5bbb; }
.alert-secondary hr {
border-top-color: #944ab2; }
.alert-secondary .alert-link {
color: #44115a; }

.alert-success {
color: #2e7431;
background-color: #74b077;
border-color: #6ead71; }
.alert-success hr {
border-top-color: #5ea461; }
.alert-success .alert-link {
color: #204f22; }

.alert-info {
color: #0270ab;
background-color: #4eacdf;
border-color: #46a8dd; }
.alert-info hr {
border-top-color: #309ed9; }
.alert-info .alert-link {
color: #014f79; }

.alert-warning {
color: #d18300;
background-color: #ffbd4d;
border-color: #ffba45; }
.alert-warning hr {
border-top-color: #ffb12c; }
.alert-warning .alert-link {
color: #9e6300; }

.alert-danger {
color: #ad2727;
background-color: #e06d6d;
border-color: #df6767; }
.alert-danger hr {
border-top-color: #db5252; }
.alert-danger .alert-link {
color: #831e1e; }

.alert-light {
color: #c9c9c9;
background-color: #f8f8f8;
border-color: #f8f8f8; }
.alert-light hr {
border-top-color: #ebebeb; }
.alert-light .alert-link {
color: #b0b0b0; }

.alert-dark {
color: #1b1b1b;
background-color: #646464;
border-color: #5d5d5d; }
.alert-dark hr {
border-top-color: #505050; }
.alert-dark .alert-link {
color: #020202; }

@keyframes progress-bar-stripes {
from {
background-position: 1rem 0; }
to {
background-position: 0 0; } }

.progress {
display: flex;
height: 1rem;
overflow: hidden;
font-size: 0.75rem;
background-color: #eeeeee; }

.progress-bar {
display: flex;
align-items: center;
justify-content: center;
color: #fff;
background-color: #303f9f; }

.progress-bar-striped {
background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
background-size: 1rem 1rem; }

.progress-bar-animated {
animation: progress-bar-stripes 1s linear infinite; }

.media {
display: flex;
align-items: flex-start; }

.media-body {
flex: 1; }

.list-group {
display: flex;
flex-direction: column;
padding-left: 0;
margin-bottom: 0; }

.list-group-item-action {
width: 100%;
color: #616161;
text-align: inherit; }
.list-group-item-action:focus, .list-group-item-action:hover {
color: #616161;
text-decoration: none;
background-color: #f5f5f5; }
.list-group-item-action:active {
color: #212121;
background-color: #eeeeee; }

.list-group-item {
position: relative;
display: block;
padding: 0.75rem 1.25rem;
margin-bottom: -1px;
background-color: #fff;
border: 1px solid rgba(0, 0, 0, 0.125); }
.list-group-item:last-child {
margin-bottom: 0; }
.list-group-item:focus, .list-group-item:hover {
text-decoration: none; }
.list-group-item.disabled, .list-group-item:disabled {
color: #757575;
background-color: #fff; }
.list-group-item.active {
z-index: 2;
color: #fff;
background-color: #303f9f;
border-color: #303f9f; }

.list-group-flush .list-group-item {
border-right: 0;
border-left: 0;
border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
border-bottom: 0; }

.list-group-item-primary {
color: #273482;
background-color: #6873b9; }

a.list-group-item-primary,
button.list-group-item-primary {
color: #273482; }
a.list-group-item-primary:focus, a.list-group-item-primary:hover,
button.list-group-item-primary:focus,
button.list-group-item-primary:hover {
color: #273482;
background-color: #5763b1; }
a.list-group-item-primary.active,
button.list-group-item-primary.active {
color: #fff;
background-color: #273482;
border-color: #273482; }

.list-group-item-secondary {
color: #651985;
background-color: #9f5bbb; }

a.list-group-item-secondary,
button.list-group-item-secondary {
color: #651985; }
a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
button.list-group-item-secondary:focus,
button.list-group-item-secondary:hover {
color: #651985;
background-color: #944ab2; }
a.list-group-item-secondary.active,
button.list-group-item-secondary.active {
color: #fff;
background-color: #651985;
border-color: #651985; }

.list-group-item-success {
color: #2e7431;
background-color: #6ead71; }

a.list-group-item-success,
button.list-group-item-success {
color: #2e7431; }
a.list-group-item-success:focus, a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
color: #2e7431;
background-color: #5ea461; }
a.list-group-item-success.active,
button.list-group-item-success.active {
color: #fff;
background-color: #2e7431;
border-color: #2e7431; }

.list-group-item-info {
color: #0270ab;
background-color: #46a8dd; }

a.list-group-item-info,
button.list-group-item-info {
color: #0270ab; }
a.list-group-item-info:focus, a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
color: #0270ab;
background-color: #309ed9; }
a.list-group-item-info.active,
button.list-group-item-info.active {
color: #fff;
background-color: #0270ab;
border-color: #0270ab; }

.list-group-item-warning {
color: #d18300;
background-color: #ffba45; }

a.list-group-item-warning,
button.list-group-item-warning {
color: #d18300; }
a.list-group-item-warning:focus, a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
color: #d18300;
background-color: #ffb12c; }
a.list-group-item-warning.active,
button.list-group-item-warning.active {
color: #fff;
background-color: #d18300;
border-color: #d18300; }

.list-group-item-danger {
color: #ad2727;
background-color: #df6767; }

a.list-group-item-danger,
button.list-group-item-danger {
color: #ad2727; }
a.list-group-item-danger:focus, a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
color: #ad2727;
background-color: #db5252; }
a.list-group-item-danger.active,
button.list-group-item-danger.active {
color: #fff;
background-color: #ad2727;
border-color: #ad2727; }

.list-group-item-light {
color: #c9c9c9;
background-color: #f8f8f8; }

a.list-group-item-light,
button.list-group-item-light {
color: #c9c9c9; }
a.list-group-item-light:focus, a.list-group-item-light:hover,
button.list-group-item-light:focus,
button.list-group-item-light:hover {
color: #c9c9c9;
background-color: #ebebeb; }
a.list-group-item-light.active,
button.list-group-item-light.active {
color: #fff;
background-color: #c9c9c9;
border-color: #c9c9c9; }

.list-group-item-dark {
color: #1b1b1b;
background-color: #5d5d5d; }

a.list-group-item-dark,
button.list-group-item-dark {
color: #1b1b1b; }
a.list-group-item-dark:focus, a.list-group-item-dark:hover,
button.list-group-item-dark:focus,
button.list-group-item-dark:hover {
color: #1b1b1b;
background-color: #505050; }
a.list-group-item-dark.active,
button.list-group-item-dark.active {
color: #fff;
background-color: #1b1b1b;
border-color: #1b1b1b; }

.close {
float: right;
font-size: 1.5rem;
font-weight: bold;
line-height: 1;
color: #000;
text-shadow: 0 1px 0 #fff;
opacity: 0.5; }
.close:focus, .close:hover {
color: #000;
text-decoration: none;
opacity: 0.75; }

button.close {
padding: 0;
background: transparent;
border: 0;
-webkit-appearance: none; }

.modal-open {
overflow: hidden; }

.modal {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1050;
display: none;
overflow: hidden;
outline: 0; }
.modal.fade .modal-dialog {
transform: translate(0, -25%); }
.modal.show .modal-dialog {
transform: translate(0, 0); }

.modal-open .modal {
overflow-x: hidden;
overflow-y: auto; }

.modal-dialog {
position: relative;
width: auto;
margin: 10px; }

.modal-content {
position: relative;
display: flex;
flex-direction: column;
background-color: #fff;
background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.2);
outline: 0; }

.modal-backdrop {
position: fixed;
top: 0;
right: 0;
bottom: 0;
left: 0;
z-index: 1040;
background-color: #000; }
.modal-backdrop.fade {
opacity: 0; }
.modal-backdrop.show {
opacity: 0.5; }

.modal-header {
display: flex;
align-items: center;
justify-content: space-between;
padding: 15px;
border-bottom: 1px solid #eeeeee; }
.modal-header .close {
margin-left: auto; }

.modal-title {
margin-bottom: 0;
line-height: 1.5; }

.modal-body {
position: relative;
flex: 1 1 auto;
padding: 15px; }

.modal-footer {
display: flex;
align-items: center;
justify-content: flex-end;
padding: 15px;
border-top: 1px solid #eeeeee; }
.modal-footer > :not(:first-child) {
margin-left: 0.25rem; }
.modal-footer > :not(:last-child) {
margin-right: 0.25rem; }

.modal-scrollbar-measure {
position: absolute;
top: -9999px;
width: 50px;
height: 50px;
overflow: scroll; }

@media (min-width: 576px) {
.modal-dialog {
max-width: 500px;
margin: 30px auto; }
.modal-sm {
max-width: 300px; } }

@media (min-width: 992px) {
.modal-lg {
max-width: 800px; } }

.tooltip {
position: absolute;
z-index: 1070;
display: block;
margin: 0;
font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
font-style: normal;
font-weight: normal;
line-height: 1.5;
text-align: left;
text-align: start;
text-decoration: none;
text-shadow: none;
text-transform: none;
letter-spacing: normal;
word-break: normal;
word-spacing: normal;
white-space: normal;
line-break: auto;
font-size: 0.875rem;
word-wrap: break-word;
opacity: 0; }
.tooltip.show {
opacity: 0.9; }
.tooltip .arrow {
position: absolute;
display: block;
width: 5px;
height: 5px; }
.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
padding: 5px 0; }
.tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
bottom: 0; }
.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
margin-left: -3px;
content: "";
border-width: 5px 5px 0;
border-top-color: #000; }
.tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
padding: 0 5px; }
.tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
left: 0; }
.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
margin-top: -3px;
content: "";
border-width: 5px 5px 5px 0;
border-right-color: #000; }
.tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
padding: 5px 0; }
.tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
top: 0; }
.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
margin-left: -3px;
content: "";
border-width: 0 5px 5px;
border-bottom-color: #000; }
.tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
padding: 0 5px; }
.tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
right: 0; }
.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
right: 0;
margin-top: -3px;
content: "";
border-width: 5px 0 5px 5px;
border-left-color: #000; }
.tooltip .arrow::before {
position: absolute;
border-color: transparent;
border-style: solid; }

.tooltip-inner {
max-width: 200px;
padding: 3px 8px;
color: #fff;
text-align: center;
background-color: #000; }

.popover {
position: absolute;
top: 0;
left: 0;
z-index: 1060;
display: block;
max-width: 276px;
padding: 1px;
font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
font-style: normal;
font-weight: normal;
line-height: 1.5;
text-align: left;
text-align: start;
text-decoration: none;
text-shadow: none;
text-transform: none;
letter-spacing: normal;
word-break: normal;
word-spacing: normal;
white-space: normal;
line-break: auto;
font-size: 0.875rem;
word-wrap: break-word;
background-color: #fff;
background-clip: padding-box;
border: 1px solid rgba(0, 0, 0, 0.2); }
.popover .arrow {
position: absolute;
display: block;
width: 10px;
height: 5px; }
.popover .arrow::before,
.popover .arrow::after {
position: absolute;
display: block;
border-color: transparent;
border-style: solid; }
.popover .arrow::before {
content: "";
border-width: 11px; }
.popover .arrow::after {
content: "";
border-width: 11px; }
.popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
margin-bottom: 10px; }
.popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
bottom: 0; }
.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^="top"] .arrow::after {
border-bottom-width: 0; }
.popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
bottom: -11px;
margin-left: -6px;
border-top-color: rgba(0, 0, 0, 0.25); }

.popover.bs-popover-top .arrow::after,
.popover.bs-popover-auto[x-placement^="top"] .arrow::after {
bottom: -10px;
margin-left: -6px;
border-top-color: #fff; }
.popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
margin-left: 10px; }
.popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
left: 0; }
.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^="right"] .arrow::after {
margin-top: -8px;
border-left-width: 0; }
.popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
left: -11px;
border-right-color: rgba(0, 0, 0, 0.25); }

.popover.bs-popover-right .arrow::after,
.popover.bs-popover-auto[x-placement^="right"] .arrow::after {
left: -10px;
border-right-color: #fff; }
.popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
margin-top: 10px; }
.popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
top: 0; }
.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
margin-left: -7px;
border-top-width: 0; }
.popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
top: -11px;
border-bottom-color: rgba(0, 0, 0, 0.25); }

.popover.bs-popover-bottom .arrow::after,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
top: -10px;
border-bottom-color: #fff; }
.popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
position: absolute;
top: 0;
left: 50%;
display: block;
width: 20px;
margin-left: -10px;
content: "";
border-bottom: 1px solid #f7f7f7; }
.popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
margin-right: 10px; }
.popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
right: 0; }
.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^="left"] .arrow::after {
margin-top: -8px;
border-right-width: 0; }
.popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
right: -11px;
border-left-color: rgba(0, 0, 0, 0.25); }

.popover.bs-popover-left .arrow::after,
.popover.bs-popover-auto[x-placement^="left"] .arrow::after {
right: -10px;
border-left-color: #fff; }

.popover-header {
padding: 8px 14px;
margin-bottom: 0;
font-size: 1rem;
color: inherit;
background-color: #f7f7f7;
border-bottom: 1px solid #ebebeb; }
.popover-header:empty {
display: none; }

.popover-body {
padding: 9px 14px;
color: #212121; }

.carousel {
position: relative; }

.carousel-inner {
position: relative;
width: 100%;
overflow: hidden; }

.carousel-item {
position: relative;
display: none;
align-items: center;
width: 100%;
backface-visibility: hidden;
perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
display: block; }

.carousel-item-next,
.carousel-item-prev {
position: absolute;
top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
transform: translateX(0); }
@supports (transform-style: preserve-3d) {
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
transform: translateX(100%); }
@supports (transform-style: preserve-3d) {
.carousel-item-next,
.active.carousel-item-right {
transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
transform: translateX(-100%); }
@supports (transform-style: preserve-3d) {
.carousel-item-prev,
.active.carousel-item-left {
transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
position: absolute;
top: 0;
bottom: 0;
display: flex;
align-items: center;
justify-content: center;
width: 15%;
color: #fff;
text-align: center;
opacity: 0.5; }
.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
color: #fff;
text-decoration: none;
outline: 0;
opacity: 0.9; }

.carousel-control-prev {
left: 0; }

.carousel-control-next {
right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
display: inline-block;
width: 20px;
height: 20px;
background: transparent no-repeat center center;
background-size: 100% 100%; }

.carousel-control-prev-icon {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
position: absolute;
right: 0;
bottom: 10px;
left: 0;
z-index: 15;
display: flex;
justify-content: center;
padding-left: 0;
margin-right: 15%;
margin-left: 15%;
list-style: none; }
.carousel-indicators li {
position: relative;
flex: 0 1 auto;
width: 30px;
height: 3px;
margin-right: 3px;
margin-left: 3px;
text-indent: -999px;
background-color: rgba(255, 255, 255, 0.5); }
.carousel-indicators li::before {
position: absolute;
top: -10px;
left: 0;
display: inline-block;
width: 100%;
height: 10px;
content: ""; }
.carousel-indicators li::after {
position: absolute;
bottom: -10px;
left: 0;
display: inline-block;
width: 100%;
height: 10px;
content: ""; }
.carousel-indicators .active {
background-color: #fff; }

.carousel-caption {
position: absolute;
right: 15%;
bottom: 20px;
left: 15%;
z-index: 10;
padding-top: 20px;
padding-bottom: 20px;
color: #fff;
text-align: center; }

.align-baseline {
vertical-align: baseline !important; }

.align-top {
vertical-align: top !important; }

.align-middle {
vertical-align: middle !important; }

.align-bottom {
vertical-align: bottom !important; }

.align-text-bottom {
vertical-align: text-bottom !important; }

.align-text-top {
vertical-align: text-top !important; }

.bg-primary {
background-color: #303f9f !important; }

a.bg-primary:focus, a.bg-primary:hover {
background-color: #242f78 !important; }

.bg-secondary {
background-color: #7b1fa2 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
background-color: #5a1777 !important; }

.bg-success {
background-color: #388e3c !important; }

a.bg-success:focus, a.bg-success:hover {
background-color: #2a692d !important; }

.bg-info {
background-color: #0288d1 !important; }

a.bg-info:focus, a.bg-info:hover {
background-color: #02679e !important; }

.bg-warning {
background-color: #ffa000 !important; }

a.bg-warning:focus, a.bg-warning:hover {
background-color: #cc8000 !important; }

.bg-danger {
background-color: #d32f2f !important; }

a.bg-danger:focus, a.bg-danger:hover {
background-color: #ab2424 !important; }

.bg-light {
background-color: #f5f5f5 !important; }

a.bg-light:focus, a.bg-light:hover {
background-color: gainsboro !important; }

.bg-dark {
background-color: #212121 !important; }

a.bg-dark:focus, a.bg-dark:hover {
background-color: #080808 !important; }

.bg-white {
background-color: #fff !important; }

.bg-transparent {
background-color: transparent !important; }

.border {
border: 1px solid #eeeeee !important; }

.border-0 {
border: 0 !important; }

.border-top-0 {
border-top: 0 !important; }

.border-right-0 {
border-right: 0 !important; }

.border-bottom-0 {
border-bottom: 0 !important; }

.border-left-0 {
border-left: 0 !important; }

.border-primary {
border-color: #303f9f !important; }

.border-secondary {
border-color: #7b1fa2 !important; }

.border-success {
border-color: #388e3c !important; }

.border-info {
border-color: #0288d1 !important; }

.border-warning {
border-color: #ffa000 !important; }

.border-danger {
border-color: #d32f2f !important; }

.border-light {
border-color: #f5f5f5 !important; }

.border-dark {
border-color: #212121 !important; }

.border-white {
border-color: #fff !important; }

.rounded {
border-radius: 12px !important; }

.rounded-top {
border-top-left-radius: 12px !important;
border-top-right-radius: 12px !important; }

.rounded-right {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important; 
}

.rounded-bottom {
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important; 
}

.rounded-left {
  border-top-left-radius: 12px !important;  
  border-bottom-left-radius: 12px !important; 
}

/* added Joel */
.rounded-left-main {
  border-top-left-radius: 12px !important;  
  border-bottom-left-radius: 12px !important; 
}

.rounded-right-main {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important; 
}

.rounded-circle {
border-radius: 50%; }

.rounded-0 {
border-radius: 0; }

.clearfix::after {
display: block;
clear: both;
content: ""; }

.d-none {
display: none !important; }

.d-inline {
display: inline !important; }

.d-inline-block {
display: inline-block !important; }

.d-block {
display: block !important; }

.d-table {
display: table !important; }

.d-table-cell {
display: table-cell !important; }

.d-flex {
display: flex !important; }

.d-inline-flex {
display: inline-flex !important; }

@media (min-width: 576px) {
.d-sm-none {
display: none !important; }
.d-sm-inline {
display: inline !important; }
.d-sm-inline-block {
display: inline-block !important; }
.d-sm-block {
display: block !important; }
.d-sm-table {
display: table !important; }
.d-sm-table-cell {
display: table-cell !important; }
.d-sm-flex {
display: flex !important; }
.d-sm-inline-flex {
display: inline-flex !important; } }

@media (min-width: 768px) {
.d-md-none {
display: none !important; }
.d-md-inline {
display: inline !important; }
.d-md-inline-block {
display: inline-block !important; }
.d-md-block {
display: block !important; }
.d-md-table {
display: table !important; }
.d-md-table-cell {
display: table-cell !important; }
.d-md-flex {
display: flex !important; }
.d-md-inline-flex {
display: inline-flex !important; } }

@media (min-width: 992px) {
.d-lg-none {
display: none !important; }
.d-lg-inline {
display: inline !important; }
.d-lg-inline-block {
display: inline-block !important; }
.d-lg-block {
display: block !important; }
.d-lg-table {
display: table !important; }
.d-lg-table-cell {
display: table-cell !important; }
.d-lg-flex {
display: flex !important; }
.d-lg-inline-flex {
display: inline-flex !important; } }

@media (min-width: 1200px) {
.d-xl-none {
display: none !important; }
.d-xl-inline {
display: inline !important; }
.d-xl-inline-block {
display: inline-block !important; }
.d-xl-block {
display: block !important; }
.d-xl-table {
display: table !important; }
.d-xl-table-cell {
display: table-cell !important; }
.d-xl-flex {
display: flex !important; }
.d-xl-inline-flex {
display: inline-flex !important; } }

.d-print-block {
display: none !important; }
@media print {
.d-print-block {
display: block !important; } }

.d-print-inline {
display: none !important; }
@media print {
.d-print-inline {
display: inline !important; } }

.d-print-inline-block {
display: none !important; }
@media print {
.d-print-inline-block {
display: inline-block !important; } }

@media print {
.d-print-none {
display: none !important; } }

.embed-responsive {
position: relative;
display: block;
width: 100%;
padding: 0;
overflow: hidden; }
.embed-responsive::before {
display: block;
content: ""; }
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
position: absolute;
top: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
border: 0; }

.embed-responsive-21by9::before {
padding-top: 42.85714%; }

.embed-responsive-16by9::before {
padding-top: 56.25%; }

.embed-responsive-4by3::before {
padding-top: 75%; }

.embed-responsive-1by1::before {
padding-top: 100%; }

.flex-row {
flex-direction: row !important; }

.flex-column {
flex-direction: column !important; }

.flex-row-reverse {
flex-direction: row-reverse !important; }

.flex-column-reverse {
flex-direction: column-reverse !important; }

.flex-wrap {
flex-wrap: wrap !important; }

.flex-nowrap {
flex-wrap: nowrap !important; }

.flex-wrap-reverse {
flex-wrap: wrap-reverse !important; }

.justify-content-start {
justify-content: flex-start !important; }

.justify-content-end {
justify-content: flex-end !important; }

.justify-content-center {
justify-content: center !important; }

.justify-content-between {
justify-content: space-between !important; }

.justify-content-around {
justify-content: space-around !important; }

.align-items-start {
align-items: flex-start !important; }

.align-items-end {
align-items: flex-end !important; }

.align-items-center {
align-items: center !important; }

.align-items-baseline {
align-items: baseline !important; }

.align-items-stretch {
align-items: stretch !important; }

.align-content-start {
align-content: flex-start !important; }

.align-content-end {
align-content: flex-end !important; }

.align-content-center {
align-content: center !important; }

.align-content-between {
align-content: space-between !important; }

.align-content-around {
align-content: space-around !important; }

.align-content-stretch {
align-content: stretch !important; }

.align-self-auto {
align-self: auto !important; }

.align-self-start {
align-self: flex-start !important; }

.align-self-end {
align-self: flex-end !important; }

.align-self-center {
align-self: center !important; }

.align-self-baseline {
align-self: baseline !important; }

.align-self-stretch {
align-self: stretch !important; }

@media (min-width: 576px) {
.flex-sm-row {
flex-direction: row !important; }
.flex-sm-column {
flex-direction: column !important; }
.flex-sm-row-reverse {
flex-direction: row-reverse !important; }
.flex-sm-column-reverse {
flex-direction: column-reverse !important; }
.flex-sm-wrap {
flex-wrap: wrap !important; }
.flex-sm-nowrap {
flex-wrap: nowrap !important; }
.flex-sm-wrap-reverse {
flex-wrap: wrap-reverse !important; }
.justify-content-sm-start {
justify-content: flex-start !important; }
.justify-content-sm-end {
justify-content: flex-end !important; }
.justify-content-sm-center {
justify-content: center !important; }
.justify-content-sm-between {
justify-content: space-between !important; }
.justify-content-sm-around {
justify-content: space-around !important; }
.align-items-sm-start {
align-items: flex-start !important; }
.align-items-sm-end {
align-items: flex-end !important; }
.align-items-sm-center {
align-items: center !important; }
.align-items-sm-baseline {
align-items: baseline !important; }
.align-items-sm-stretch {
align-items: stretch !important; }
.align-content-sm-start {
align-content: flex-start !important; }
.align-content-sm-end {
align-content: flex-end !important; }
.align-content-sm-center {
align-content: center !important; }
.align-content-sm-between {
align-content: space-between !important; }
.align-content-sm-around {
align-content: space-around !important; }
.align-content-sm-stretch {
align-content: stretch !important; }
.align-self-sm-auto {
align-self: auto !important; }
.align-self-sm-start {
align-self: flex-start !important; }
.align-self-sm-end {
align-self: flex-end !important; }
.align-self-sm-center {
align-self: center !important; }
.align-self-sm-baseline {
align-self: baseline !important; }
.align-self-sm-stretch {
align-self: stretch !important; } }

@media (min-width: 768px) {
.flex-md-row {
flex-direction: row !important; }
.flex-md-column {
flex-direction: column !important; }
.flex-md-row-reverse {
flex-direction: row-reverse !important; }
.flex-md-column-reverse {
flex-direction: column-reverse !important; }
.flex-md-wrap {
flex-wrap: wrap !important; }
.flex-md-nowrap {
flex-wrap: nowrap !important; }
.flex-md-wrap-reverse {
flex-wrap: wrap-reverse !important; }
.justify-content-md-start {
justify-content: flex-start !important; }
.justify-content-md-end {
justify-content: flex-end !important; }
.justify-content-md-center {
justify-content: center !important; }
.justify-content-md-between {
justify-content: space-between !important; }
.justify-content-md-around {
justify-content: space-around !important; }
.align-items-md-start {
align-items: flex-start !important; }
.align-items-md-end {
align-items: flex-end !important; }
.align-items-md-center {
align-items: center !important; }
.align-items-md-baseline {
align-items: baseline !important; }
.align-items-md-stretch {
align-items: stretch !important; }
.align-content-md-start {
align-content: flex-start !important; }
.align-content-md-end {
align-content: flex-end !important; }
.align-content-md-center {
align-content: center !important; }
.align-content-md-between {
align-content: space-between !important; }
.align-content-md-around {
align-content: space-around !important; }
.align-content-md-stretch {
align-content: stretch !important; }
.align-self-md-auto {
align-self: auto !important; }
.align-self-md-start {
align-self: flex-start !important; }
.align-self-md-end {
align-self: flex-end !important; }
.align-self-md-center {
align-self: center !important; }
.align-self-md-baseline {
align-self: baseline !important; }
.align-self-md-stretch {
align-self: stretch !important; } }

@media (min-width: 992px) {
.flex-lg-row {
flex-direction: row !important; }
.flex-lg-column {
flex-direction: column !important; }
.flex-lg-row-reverse {
flex-direction: row-reverse !important; }
.flex-lg-column-reverse {
flex-direction: column-reverse !important; }
.flex-lg-wrap {
flex-wrap: wrap !important; }
.flex-lg-nowrap {
flex-wrap: nowrap !important; }
.flex-lg-wrap-reverse {
flex-wrap: wrap-reverse !important; }
.justify-content-lg-start {
justify-content: flex-start !important; }
.justify-content-lg-end {
justify-content: flex-end !important; }
.justify-content-lg-center {
justify-content: center !important; }
.justify-content-lg-between {
justify-content: space-between !important; }
.justify-content-lg-around {
justify-content: space-around !important; }
.align-items-lg-start {
align-items: flex-start !important; }
.align-items-lg-end {
align-items: flex-end !important; }
.align-items-lg-center {
align-items: center !important; }
.align-items-lg-baseline {
align-items: baseline !important; }
.align-items-lg-stretch {
align-items: stretch !important; }
.align-content-lg-start {
align-content: flex-start !important; }
.align-content-lg-end {
align-content: flex-end !important; }
.align-content-lg-center {
align-content: center !important; }
.align-content-lg-between {
align-content: space-between !important; }
.align-content-lg-around {
align-content: space-around !important; }
.align-content-lg-stretch {
align-content: stretch !important; }
.align-self-lg-auto {
align-self: auto !important; }
.align-self-lg-start {
align-self: flex-start !important; }
.align-self-lg-end {
align-self: flex-end !important; }
.align-self-lg-center {
align-self: center !important; }
.align-self-lg-baseline {
align-self: baseline !important; }
.align-self-lg-stretch {
align-self: stretch !important; } }

@media (min-width: 1200px) {
.flex-xl-row {
flex-direction: row !important; }
.flex-xl-column {
flex-direction: column !important; }
.flex-xl-row-reverse {
flex-direction: row-reverse !important; }
.flex-xl-column-reverse {
flex-direction: column-reverse !important; }
.flex-xl-wrap {
flex-wrap: wrap !important; }
.flex-xl-nowrap {
flex-wrap: nowrap !important; }
.flex-xl-wrap-reverse {
flex-wrap: wrap-reverse !important; }
.justify-content-xl-start {
justify-content: flex-start !important; }
.justify-content-xl-end {
justify-content: flex-end !important; }
.justify-content-xl-center {
justify-content: center !important; }
.justify-content-xl-between {
justify-content: space-between !important; }
.justify-content-xl-around {
justify-content: space-around !important; }
.align-items-xl-start {
align-items: flex-start !important; }
.align-items-xl-end {
align-items: flex-end !important; }
.align-items-xl-center {
align-items: center !important; }
.align-items-xl-baseline {
align-items: baseline !important; }
.align-items-xl-stretch {
align-items: stretch !important; }
.align-content-xl-start {
align-content: flex-start !important; }
.align-content-xl-end {
align-content: flex-end !important; }
.align-content-xl-center {
align-content: center !important; }
.align-content-xl-between {
align-content: space-between !important; }
.align-content-xl-around {
align-content: space-around !important; }
.align-content-xl-stretch {
align-content: stretch !important; }
.align-self-xl-auto {
align-self: auto !important; }
.align-self-xl-start {
align-self: flex-start !important; }
.align-self-xl-end {
align-self: flex-end !important; }
.align-self-xl-center {
align-self: center !important; }
.align-self-xl-baseline {
align-self: baseline !important; }
.align-self-xl-stretch {
align-self: stretch !important; } }

.float-left {
float: left !important; }

.float-right {
float: right !important; }

.float-none {
float: none !important; }

@media (min-width: 576px) {
.float-sm-left {
float: left !important; }
.float-sm-right {
float: right !important; }
.float-sm-none {
float: none !important; } }

@media (min-width: 768px) {
.float-md-left {
float: left !important; }
.float-md-right {
float: right !important; }
.float-md-none {
float: none !important; } }

@media (min-width: 992px) {
.float-lg-left {
float: left !important; }
.float-lg-right {
float: right !important; }
.float-lg-none {
float: none !important; } }

@media (min-width: 1200px) {
.float-xl-left {
float: left !important; }
.float-xl-right {
float: right !important; }
.float-xl-none {
float: none !important; } }

.position-static {
position: static !important; }

.position-relative {
position: relative !important; }

.position-absolute {
position: absolute !important; }

.position-fixed {
position: fixed !important; }

.position-sticky {
position: sticky !important; }

.fixed-top {
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: 1030; }

.fixed-bottom {
position: fixed;
right: 0;
bottom: 0;
left: 0;
z-index: 1030; }

@supports (position: sticky) {
.sticky-top {
position: sticky;
top: 0;
z-index: 1020; } }

.sr-only {
position: absolute;
width: 1px;
height: 1px;
padding: 0;
overflow: hidden;
clip: rect(0, 0, 0, 0);
white-space: nowrap;
clip-path: inset(50%);
border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
position: static;
width: auto;
height: auto;
overflow: visible;
clip: auto;
white-space: normal;
clip-path: none; }

.w-25 {
width: 25% !important; }

.w-50 {
width: 50% !important; }

.w-75 {
width: 75% !important; }

.w-100 {
width: 100% !important; }

.h-25 {
height: 25% !important; }

.h-50 {
height: 50% !important; }

.h-75 {
height: 75% !important; }

.h-100 {
height: 100% !important; }

.mw-100 {
max-width: 100% !important; }

.mh-100 {
max-height: 100% !important; }

.m-0 {
margin: 0 !important; }

.mt-0,
.my-0 {
margin-top: 0 !important; }

.mr-0,
.mx-0 {
margin-right: 0 !important; }

.mb-0,
.my-0 {
margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
margin-left: 0 !important; }

.m-1 {
margin: 0.25rem !important; }

.mt-1,
.my-1 {
margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
margin-left: 0.25rem !important; }

.m-2 {
margin: 0.5rem !important; }

.mt-2,
.my-2 {
margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
margin-left: 0.5rem !important; }

.m-3 {
margin: 1rem !important; }

.mt-3,
.my-3 {
margin-top: 1rem !important; }

.mr-3,
.mx-3 {
margin-right: 1rem !important; }

.mb-3,
.my-3 {
margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
margin-left: 1rem !important; }

.m-4 {
margin: 1.5rem !important; }

.mt-4,
.my-4 {
margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
margin-left: 1.5rem !important; }

.m-5 {
margin: 3rem !important; }

.mt-5,
.my-5 {
margin-top: 3rem !important; }

.mr-5,
.mx-5 {
margin-right: 3rem !important; }

.mb-5,
.my-5 {
margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
margin-left: 3rem !important; }

.p-0 {
padding: 0 !important; }

.pt-0,
.py-0 {
padding-top: 0 !important; }

.pr-0,
.px-0 {
padding-right: 0 !important; }

.pb-0,
.py-0 {
padding-bottom: 0 !important; }

.pl-0,
.px-0 {
padding-left: 0 !important; }

.p-1 {
padding: 0.25rem !important; }

.pt-1,
.py-1 {
padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
padding-left: 0.25rem !important; }

.p-2 {
padding: 0.5rem !important; }

.pt-2,
.py-2 {
padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
padding-left: 0.5rem !important; }

.p-3 {
padding: 1rem !important; }

.pt-3,
.py-3 {
padding-top: 1rem !important; }

.pr-3,
.px-3 {
padding-right: 1rem !important; }

.pb-3,
.py-3 {
padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
padding-left: 1rem !important; }

.p-4 {
padding: 1.5rem !important; }

.pt-4,
.py-4 {
padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
padding-left: 1.5rem !important; }

.p-5 {
padding: 3rem !important; }

.pt-5,
.py-5 {
padding-top: 3rem !important; }

.pr-5,
.px-5 {
padding-right: 3rem !important; }

.pb-5,
.py-5 {
padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
padding-left: 3rem !important; }

.m-auto {
margin: auto !important; }

.mt-auto,
.my-auto {
margin-top: auto !important; }

.mr-auto,
.mx-auto {
margin-right: auto !important; }

.mb-auto,
.my-auto {
margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
margin-left: auto !important; }

@media (min-width: 576px) {
.m-sm-0 {
margin: 0 !important; }
.mt-sm-0,
.my-sm-0 {
margin-top: 0 !important; }
.mr-sm-0,
.mx-sm-0 {
margin-right: 0 !important; }
.mb-sm-0,
.my-sm-0 {
margin-bottom: 0 !important; }
.ml-sm-0,
.mx-sm-0 {
margin-left: 0 !important; }
.m-sm-1 {
margin: 0.25rem !important; }
.mt-sm-1,
.my-sm-1 {
margin-top: 0.25rem !important; }
.mr-sm-1,
.mx-sm-1 {
margin-right: 0.25rem !important; }
.mb-sm-1,
.my-sm-1 {
margin-bottom: 0.25rem !important; }
.ml-sm-1,
.mx-sm-1 {
margin-left: 0.25rem !important; }
.m-sm-2 {
margin: 0.5rem !important; }
.mt-sm-2,
.my-sm-2 {
margin-top: 0.5rem !important; }
.mr-sm-2,
.mx-sm-2 {
margin-right: 0.5rem !important; }
.mb-sm-2,
.my-sm-2 {
margin-bottom: 0.5rem !important; }
.ml-sm-2,
.mx-sm-2 {
margin-left: 0.5rem !important; }
.m-sm-3 {
margin: 1rem !important; }
.mt-sm-3,
.my-sm-3 {
margin-top: 1rem !important; }
.mr-sm-3,
.mx-sm-3 {
margin-right: 1rem !important; }
.mb-sm-3,
.my-sm-3 {
margin-bottom: 1rem !important; }
.ml-sm-3,
.mx-sm-3 {
margin-left: 1rem !important; }
.m-sm-4 {
margin: 1.5rem !important; }
.mt-sm-4,
.my-sm-4 {
margin-top: 1.5rem !important; }
.mr-sm-4,
.mx-sm-4 {
margin-right: 1.5rem !important; }
.mb-sm-4,
.my-sm-4 {
margin-bottom: 1.5rem !important; }
.ml-sm-4,
.mx-sm-4 {
margin-left: 1.5rem !important; }
.m-sm-5 {
margin: 3rem !important; }
.mt-sm-5,
.my-sm-5 {
margin-top: 3rem !important; }
.mr-sm-5,
.mx-sm-5 {
margin-right: 3rem !important; }
.mb-sm-5,
.my-sm-5 {
margin-bottom: 3rem !important; }
.ml-sm-5,
.mx-sm-5 {
margin-left: 3rem !important; }
.p-sm-0 {
padding: 0 !important; }
.pt-sm-0,
.py-sm-0 {
padding-top: 0 !important; }
.pr-sm-0,
.px-sm-0 {
padding-right: 0 !important; }
.pb-sm-0,
.py-sm-0 {
padding-bottom: 0 !important; }
.pl-sm-0,
.px-sm-0 {
padding-left: 0 !important; }
.p-sm-1 {
padding: 0.25rem !important; }
.pt-sm-1,
.py-sm-1 {
padding-top: 0.25rem !important; }
.pr-sm-1,
.px-sm-1 {
padding-right: 0.25rem !important; }
.pb-sm-1,
.py-sm-1 {
padding-bottom: 0.25rem !important; }
.pl-sm-1,
.px-sm-1 {
padding-left: 0.25rem !important; }
.p-sm-2 {
padding: 0.5rem !important; }
.pt-sm-2,
.py-sm-2 {
padding-top: 0.5rem !important; }
.pr-sm-2,
.px-sm-2 {
padding-right: 0.5rem !important; }
.pb-sm-2,
.py-sm-2 {
padding-bottom: 0.5rem !important; }
.pl-sm-2,
.px-sm-2 {
padding-left: 0.5rem !important; }
.p-sm-3 {
padding: 1rem !important; }
.pt-sm-3,
.py-sm-3 {
padding-top: 1rem !important; }
.pr-sm-3,
.px-sm-3 {
padding-right: 1rem !important; }
.pb-sm-3,
.py-sm-3 {
padding-bottom: 1rem !important; }
.pl-sm-3,
.px-sm-3 {
padding-left: 1rem !important; }
.p-sm-4 {
padding: 1.5rem !important; }
.pt-sm-4,
.py-sm-4 {
padding-top: 1.5rem !important; }
.pr-sm-4,
.px-sm-4 {
padding-right: 1.5rem !important; }
.pb-sm-4,
.py-sm-4 {
padding-bottom: 1.5rem !important; }
.pl-sm-4,
.px-sm-4 {
padding-left: 1.5rem !important; }
.p-sm-5 {
padding: 3rem !important; }
.pt-sm-5,
.py-sm-5 {
padding-top: 3rem !important; }
.pr-sm-5,
.px-sm-5 {
padding-right: 3rem !important; }
.pb-sm-5,
.py-sm-5 {
padding-bottom: 3rem !important; }
.pl-sm-5,
.px-sm-5 {
padding-left: 3rem !important; }
.m-sm-auto {
margin: auto !important; }
.mt-sm-auto,
.my-sm-auto {
margin-top: auto !important; }
.mr-sm-auto,
.mx-sm-auto {
margin-right: auto !important; }
.mb-sm-auto,
.my-sm-auto {
margin-bottom: auto !important; }
.ml-sm-auto,
.mx-sm-auto {
margin-left: auto !important; } }

@media (min-width: 768px) {
.m-md-0 {
margin: 0 !important; }
.mt-md-0,
.my-md-0 {
margin-top: 0 !important; }
.mr-md-0,
.mx-md-0 {
margin-right: 0 !important; }
.mb-md-0,
.my-md-0 {
margin-bottom: 0 !important; }
.ml-md-0,
.mx-md-0 {
margin-left: 0 !important; }
.m-md-1 {
margin: 0.25rem !important; }
.mt-md-1,
.my-md-1 {
margin-top: 0.25rem !important; }
.mr-md-1,
.mx-md-1 {
margin-right: 0.25rem !important; }
.mb-md-1,
.my-md-1 {
margin-bottom: 0.25rem !important; }
.ml-md-1,
.mx-md-1 {
margin-left: 0.25rem !important; }
.m-md-2 {
margin: 0.5rem !important; }
.mt-md-2,
.my-md-2 {
margin-top: 0.5rem !important; }
.mr-md-2,
.mx-md-2 {
margin-right: 0.5rem !important; }
.mb-md-2,
.my-md-2 {
margin-bottom: 0.5rem !important; }
.ml-md-2,
.mx-md-2 {
margin-left: 0.5rem !important; }
.m-md-3 {
margin: 1rem !important; }
.mt-md-3,
.my-md-3 {
margin-top: 1rem !important; }
.mr-md-3,
.mx-md-3 {
margin-right: 1rem !important; }
.mb-md-3,
.my-md-3 {
margin-bottom: 1rem !important; }
.ml-md-3,
.mx-md-3 {
margin-left: 1rem !important; }
.m-md-4 {
margin: 1.5rem !important; }
.mt-md-4,
.my-md-4 {
margin-top: 1.5rem !important; }
.mr-md-4,
.mx-md-4 {
margin-right: 1.5rem !important; }
.mb-md-4,
.my-md-4 {
margin-bottom: 1.5rem !important; }
.ml-md-4,
.mx-md-4 {
margin-left: 1.5rem !important; }
.m-md-5 {
margin: 3rem !important; }
.mt-md-5,
.my-md-5 {
margin-top: 3rem !important; }
.mr-md-5,
.mx-md-5 {
margin-right: 3rem !important; }
.mb-md-5,
.my-md-5 {
margin-bottom: 3rem !important; }
.ml-md-5,
.mx-md-5 {
margin-left: 3rem !important; }
.p-md-0 {
padding: 0 !important; }
.pt-md-0,
.py-md-0 {
padding-top: 0 !important; }
.pr-md-0,
.px-md-0 {
padding-right: 0 !important; }
.pb-md-0,
.py-md-0 {
padding-bottom: 0 !important; }
.pl-md-0,
.px-md-0 {
padding-left: 0 !important; }
.p-md-1 {
padding: 0.25rem !important; }
.pt-md-1,
.py-md-1 {
padding-top: 0.25rem !important; }
.pr-md-1,
.px-md-1 {
padding-right: 0.25rem !important; }
.pb-md-1,
.py-md-1 {
padding-bottom: 0.25rem !important; }
.pl-md-1,
.px-md-1 {
padding-left: 0.25rem !important; }
.p-md-2 {
padding: 0.5rem !important; }
.pt-md-2,
.py-md-2 {
padding-top: 0.5rem !important; }
.pr-md-2,
.px-md-2 {
padding-right: 0.5rem !important; }
.pb-md-2,
.py-md-2 {
padding-bottom: 0.5rem !important; }
.pl-md-2,
.px-md-2 {
padding-left: 0.5rem !important; }
.p-md-3 {
padding: 1rem !important; }
.pt-md-3,
.py-md-3 {
padding-top: 1rem !important; }
.pr-md-3,
.px-md-3 {
padding-right: 1rem !important; }
.pb-md-3,
.py-md-3 {
padding-bottom: 1rem !important; }
.pl-md-3,
.px-md-3 {
padding-left: 1rem !important; }
.p-md-4 {
padding: 1.5rem !important; }
.pt-md-4,
.py-md-4 {
padding-top: 1.5rem !important; }
.pr-md-4,
.px-md-4 {
padding-right: 1.5rem !important; }
.pb-md-4,
.py-md-4 {
padding-bottom: 1.5rem !important; }
.pl-md-4,
.px-md-4 {
padding-left: 1.5rem !important; }
.p-md-5 {
padding: 3rem !important; }
.pt-md-5,
.py-md-5 {
padding-top: 3rem !important; }
.pr-md-5,
.px-md-5 {
padding-right: 3rem !important; }
.pb-md-5,
.py-md-5 {
padding-bottom: 3rem !important; }
.pl-md-5,
.px-md-5 {
padding-left: 3rem !important; }
.m-md-auto {
margin: auto !important; }
.mt-md-auto,
.my-md-auto {
margin-top: auto !important; }
.mr-md-auto,
.mx-md-auto {
margin-right: auto !important; }
.mb-md-auto,
.my-md-auto {
margin-bottom: auto !important; }
.ml-md-auto,
.mx-md-auto {
margin-left: auto !important; } }

@media (min-width: 992px) {
.m-lg-0 {
margin: 0 !important; }
.mt-lg-0,
.my-lg-0 {
margin-top: 0 !important; }
.mr-lg-0,
.mx-lg-0 {
margin-right: 0 !important; }
.mb-lg-0,
.my-lg-0 {
margin-bottom: 0 !important; }
.ml-lg-0,
.mx-lg-0 {
margin-left: 0 !important; }
.m-lg-1 {
margin: 0.25rem !important; }
.mt-lg-1,
.my-lg-1 {
margin-top: 0.25rem !important; }
.mr-lg-1,
.mx-lg-1 {
margin-right: 0.25rem !important; }
.mb-lg-1,
.my-lg-1 {
margin-bottom: 0.25rem !important; }
.ml-lg-1,
.mx-lg-1 {
margin-left: 0.25rem !important; }
.m-lg-2 {
margin: 0.5rem !important; }
.mt-lg-2,
.my-lg-2 {
margin-top: 0.5rem !important; }
.mr-lg-2,
.mx-lg-2 {
margin-right: 0.5rem !important; }
.mb-lg-2,
.my-lg-2 {
margin-bottom: 0.5rem !important; }
.ml-lg-2,
.mx-lg-2 {
margin-left: 0.5rem !important; }
.m-lg-3 {
margin: 1rem !important; }
.mt-lg-3,
.my-lg-3 {
margin-top: 1rem !important; }
.mr-lg-3,
.mx-lg-3 {
margin-right: 1rem !important; }
.mb-lg-3,
.my-lg-3 {
margin-bottom: 1rem !important; }
.ml-lg-3,
.mx-lg-3 {
margin-left: 1rem !important; }
.m-lg-4 {
margin: 1.5rem !important; }
.mt-lg-4,
.my-lg-4 {
margin-top: 1.5rem !important; }
.mr-lg-4,
.mx-lg-4 {
margin-right: 1.5rem !important; }
.mb-lg-4,
.my-lg-4 {
margin-bottom: 1.5rem !important; }
.ml-lg-4,
.mx-lg-4 {
margin-left: 1.5rem !important; }
.m-lg-5 {
margin: 3rem !important; }
.mt-lg-5,
.my-lg-5 {
margin-top: 3rem !important; }
.mr-lg-5,
.mx-lg-5 {
margin-right: 3rem !important; }
.mb-lg-5,
.my-lg-5 {
margin-bottom: 3rem !important; }
.ml-lg-5,
.mx-lg-5 {
margin-left: 3rem !important; }
.p-lg-0 {
padding: 0 !important; }
.pt-lg-0,
.py-lg-0 {
padding-top: 0 !important; }
.pr-lg-0,
.px-lg-0 {
padding-right: 0 !important; }
.pb-lg-0,
.py-lg-0 {
padding-bottom: 0 !important; }
.pl-lg-0,
.px-lg-0 {
padding-left: 0 !important; }
.p-lg-1 {
padding: 0.25rem !important; }
.pt-lg-1,
.py-lg-1 {
padding-top: 0.25rem !important; }
.pr-lg-1,
.px-lg-1 {
padding-right: 0.25rem !important; }
.pb-lg-1,
.py-lg-1 {
padding-bottom: 0.25rem !important; }
.pl-lg-1,
.px-lg-1 {
padding-left: 0.25rem !important; }
.p-lg-2 {
padding: 0.5rem !important; }
.pt-lg-2,
.py-lg-2 {
padding-top: 0.5rem !important; }
.pr-lg-2,
.px-lg-2 {
padding-right: 0.5rem !important; }
.pb-lg-2,
.py-lg-2 {
padding-bottom: 0.5rem !important; }
.pl-lg-2,
.px-lg-2 {
padding-left: 0.5rem !important; }
.p-lg-3 {
padding: 1rem !important; }
.pt-lg-3,
.py-lg-3 {
padding-top: 1rem !important; }
.pr-lg-3,
.px-lg-3 {
padding-right: 1rem !important; }
.pb-lg-3,
.py-lg-3 {
padding-bottom: 1rem !important; }
.pl-lg-3,
.px-lg-3 {
padding-left: 1rem !important; }
.p-lg-4 {
padding: 1.5rem !important; }
.pt-lg-4,
.py-lg-4 {
padding-top: 1.5rem !important; }
.pr-lg-4,
.px-lg-4 {
padding-right: 1.5rem !important; }
.pb-lg-4,
.py-lg-4 {
padding-bottom: 1.5rem !important; }
.pl-lg-4,
.px-lg-4 {
padding-left: 1.5rem !important; }
.p-lg-5 {
padding: 3rem !important; }
.pt-lg-5,
.py-lg-5 {
padding-top: 3rem !important; }
.pr-lg-5,
.px-lg-5 {
padding-right: 3rem !important; }
.pb-lg-5,
.py-lg-5 {
padding-bottom: 3rem !important; }
.pl-lg-5,
.px-lg-5 {
padding-left: 3rem !important; }
.m-lg-auto {
margin: auto !important; }
.mt-lg-auto,
.my-lg-auto {
margin-top: auto !important; }
.mr-lg-auto,
.mx-lg-auto {
margin-right: auto !important; }
.mb-lg-auto,
.my-lg-auto {
margin-bottom: auto !important; }
.ml-lg-auto,
.mx-lg-auto {
margin-left: auto !important; } }

@media (min-width: 1200px) {
.m-xl-0 {
margin: 0 !important; }
.mt-xl-0,
.my-xl-0 {
margin-top: 0 !important; }
.mr-xl-0,
.mx-xl-0 {
margin-right: 0 !important; }
.mb-xl-0,
.my-xl-0 {
margin-bottom: 0 !important; }
.ml-xl-0,
.mx-xl-0 {
margin-left: 0 !important; }
.m-xl-1 {
margin: 0.25rem !important; }
.mt-xl-1,
.my-xl-1 {
margin-top: 0.25rem !important; }
.mr-xl-1,
.mx-xl-1 {
margin-right: 0.25rem !important; }
.mb-xl-1,
.my-xl-1 {
margin-bottom: 0.25rem !important; }
.ml-xl-1,
.mx-xl-1 {
margin-left: 0.25rem !important; }
.m-xl-2 {
margin: 0.5rem !important; }
.mt-xl-2,
.my-xl-2 {
margin-top: 0.5rem !important; }
.mr-xl-2,
.mx-xl-2 {
margin-right: 0.5rem !important; }
.mb-xl-2,
.my-xl-2 {
margin-bottom: 0.5rem !important; }
.ml-xl-2,
.mx-xl-2 {
margin-left: 0.5rem !important; }
.m-xl-3 {
margin: 1rem !important; }
.mt-xl-3,
.my-xl-3 {
margin-top: 1rem !important; }
.mr-xl-3,
.mx-xl-3 {
margin-right: 1rem !important; }
.mb-xl-3,
.my-xl-3 {
margin-bottom: 1rem !important; }
.ml-xl-3,
.mx-xl-3 {
margin-left: 1rem !important; }
.m-xl-4 {
margin: 1.5rem !important; }
.mt-xl-4,
.my-xl-4 {
margin-top: 1.5rem !important; }
.mr-xl-4,
.mx-xl-4 {
margin-right: 1.5rem !important; }
.mb-xl-4,
.my-xl-4 {
margin-bottom: 1.5rem !important; }
.ml-xl-4,
.mx-xl-4 {
margin-left: 1.5rem !important; }
.m-xl-5 {
margin: 3rem !important; }
.mt-xl-5,
.my-xl-5 {
margin-top: 3rem !important; }
.mr-xl-5,
.mx-xl-5 {
margin-right: 3rem !important; }
.mb-xl-5,
.my-xl-5 {
margin-bottom: 3rem !important; }
.ml-xl-5,
.mx-xl-5 {
margin-left: 3rem !important; }
.p-xl-0 {
padding: 0 !important; }
.pt-xl-0,
.py-xl-0 {
padding-top: 0 !important; }
.pr-xl-0,
.px-xl-0 {
padding-right: 0 !important; }
.pb-xl-0,
.py-xl-0 {
padding-bottom: 0 !important; }
.pl-xl-0,
.px-xl-0 {
padding-left: 0 !important; }
.p-xl-1 {
padding: 0.25rem !important; }
.pt-xl-1,
.py-xl-1 {
padding-top: 0.25rem !important; }
.pr-xl-1,
.px-xl-1 {
padding-right: 0.25rem !important; }
.pb-xl-1,
.py-xl-1 {
padding-bottom: 0.25rem !important; }
.pl-xl-1,
.px-xl-1 {
padding-left: 0.25rem !important; }
.p-xl-2 {
padding: 0.5rem !important; }
.pt-xl-2,
.py-xl-2 {
padding-top: 0.5rem !important; }
.pr-xl-2,
.px-xl-2 {
padding-right: 0.5rem !important; }
.pb-xl-2,
.py-xl-2 {
padding-bottom: 0.5rem !important; }
.pl-xl-2,
.px-xl-2 {
padding-left: 0.5rem !important; }
.p-xl-3 {
padding: 1rem !important; }
.pt-xl-3,
.py-xl-3 {
padding-top: 1rem !important; }
.pr-xl-3,
.px-xl-3 {
padding-right: 1rem !important; }
.pb-xl-3,
.py-xl-3 {
padding-bottom: 1rem !important; }
.pl-xl-3,
.px-xl-3 {
padding-left: 1rem !important; }
.p-xl-4 {
padding: 1.5rem !important; }
.pt-xl-4,
.py-xl-4 {
padding-top: 1.5rem !important; }
.pr-xl-4,
.px-xl-4 {
padding-right: 1.5rem !important; }
.pb-xl-4,
.py-xl-4 {
padding-bottom: 1.5rem !important; }
.pl-xl-4,
.px-xl-4 {
padding-left: 1.5rem !important; }
.p-xl-5 {
padding: 3rem !important; }
.pt-xl-5,
.py-xl-5 {
padding-top: 3rem !important; }
.pr-xl-5,
.px-xl-5 {
padding-right: 3rem !important; }
.pb-xl-5,
.py-xl-5 {
padding-bottom: 3rem !important; }
.pl-xl-5,
.px-xl-5 {
padding-left: 3rem !important; }
.m-xl-auto {
margin: auto !important; }
.mt-xl-auto,
.my-xl-auto {
margin-top: auto !important; }
.mr-xl-auto,
.mx-xl-auto {
margin-right: auto !important; }
.mb-xl-auto,
.my-xl-auto {
margin-bottom: auto !important; }
.ml-xl-auto,
.mx-xl-auto {
margin-left: auto !important; } }

.text-justify {
text-align: justify !important; }

.text-nowrap {
white-space: nowrap !important; }

.text-truncate {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap; }

.text-left {
text-align: left !important; }

.text-right {
text-align: right !important; }

.text-center {
text-align: center !important; }

@media (min-width: 576px) {
.text-sm-left {
text-align: left !important; }
.text-sm-right {
text-align: right !important; }
.text-sm-center {
text-align: center !important; } }

@media (min-width: 768px) {
.text-md-left {
text-align: left !important; }
.text-md-right {
text-align: right !important; }
.text-md-center {
text-align: center !important; } }

@media (min-width: 992px) {
.text-lg-left {
text-align: left !important; }
.text-lg-right {
text-align: right !important; }
.text-lg-center {
text-align: center !important; } }

@media (min-width: 1200px) {
.text-xl-left {
text-align: left !important; }
.text-xl-right {
text-align: right !important; }
.text-xl-center {
text-align: center !important; } }

.text-lowercase {
text-transform: lowercase !important; }

.text-uppercase {
text-transform: uppercase !important; }

.text-capitalize {
text-transform: capitalize !important; }

.font-weight-light {
font-weight: 300 !important; }

.font-weight-normal {
font-weight: normal !important; }

.font-weight-bold {
font-weight: bold !important; }

.font-italic {
font-style: italic !important; }

.text-white {
color: #fff !important; }

.text-primary {
color: #303f9f !important; }

a.text-primary:focus, a.text-primary:hover {
color: #242f78 !important; }

.text-secondary {
color: #7b1fa2 !important; }

a.text-secondary:focus, a.text-secondary:hover {
color: #5a1777 !important; }

.text-success {
color: #388e3c !important; }

a.text-success:focus, a.text-success:hover {
color: #2a692d !important; }

.text-info {
color: #0288d1 !important; }

a.text-info:focus, a.text-info:hover {
color: #02679e !important; }

.text-warning {
color: #ffa000 !important; }

a.text-warning:focus, a.text-warning:hover {
color: #cc8000 !important; }

.text-danger {
color: #d32f2f !important; }

a.text-danger:focus, a.text-danger:hover {
color: #ab2424 !important; }

.text-light {
color: #f5f5f5 !important; }

a.text-light:focus, a.text-light:hover {
color: gainsboro !important; }

.text-dark {
color: #212121 !important; }

a.text-dark:focus, a.text-dark:hover {
color: #080808 !important; }

.text-muted {
color: #757575 !important; }

.text-hide {
font: 0/0 a;
color: transparent;
text-shadow: none;
background-color: transparent;
border: 0; }

.visible {
visibility: visible !important; }

.invisible {
visibility: hidden !important; }

.h-0 {
height: 0px !important; }

.min-h-0 {
min-height: 0px !important; }

.max-h-0 {
max-height: 0px !important; }

.h-10 {
height: 10px !important; }

.min-h-10 {
min-height: 10px !important; }

.max-h-10 {
max-height: 10px !important; }

.h-20 {
height: 20px !important; }

.min-h-20 {
min-height: 20px !important; }

.max-h-20 {
max-height: 20px !important; }

.h-30 {
height: 30px !important; }

.min-h-30 {
min-height: 30px !important; }

.max-h-30 {
max-height: 30px !important; }

.h-40 {
height: 40px !important; }

.min-h-40 {
min-height: 40px !important; }

.max-h-40 {
max-height: 40px !important; }

.h-50 {
height: 50px !important; }

.min-h-50 {
min-height: 50px !important; }

.max-h-50 {
max-height: 50px !important; }

.h-60 {
height: 60px !important; }

.min-h-60 {
min-height: 60px !important; }

.max-h-60 {
max-height: 60px !important; }

.h-70 {
height: 70px !important; }

.min-h-70 {
min-height: 70px !important; }

.max-h-70 {
max-height: 70px !important; }

.h-80 {
height: 80px !important; }

.min-h-80 {
min-height: 80px !important; }

.max-h-80 {
max-height: 80px !important; }

.h-90 {
height: 90px !important; }

.min-h-90 {
min-height: 90px !important; }

.max-h-90 {
max-height: 90px !important; }

.h-100 {
height: 100px !important; }

.min-h-100 {
min-height: 100px !important; }

.max-h-100 {
max-height: 100px !important; }

.h-150 {
height: 150px !important; }

.min-h-150 {
min-height: 150px !important; }

.max-h-150 {
max-height: 150px !important; }

.h-200 {
height: 200px !important; }

.min-h-200 {
min-height: 200px !important; }

.max-h-200 {
max-height: 200px !important; }

.h-250 {
height: 250px !important; }

.min-h-250 {
min-height: 250px !important; }

.max-h-250 {
max-height: 250px !important; }

.h-300 {
height: 300px !important; }

.min-h-300 {
min-height: 300px !important; }

.max-h-300 {
max-height: 300px !important; }

.h-350 {
height: 350px !important; }

.min-h-350 {
min-height: 350px !important; }

.max-h-350 {
max-height: 350px !important; }

.h-400 {
height: 400px !important; }

.min-h-400 {
min-height: 400px !important; }

.max-h-400 {
max-height: 400px !important; }

.h-450 {
height: 450px !important; }

.min-h-450 {
min-height: 450px !important; }

.max-h-450 {
max-height: 450px !important; }

.h-500 {
height: 500px !important; }

.min-h-500 {
min-height: 500px !important; }

.max-h-500 {
max-height: 500px !important; }

.m-0 {
margin: 0px !important; }

.m-5 {
margin: 5px !important; }

.m-10 {
margin: 10px !important; }

.m-15 {
margin: 15px !important; }

.m-20 {
margin: 20px !important; }

.m-25 {
margin: 25px !important; }

.m-30 {
margin: 30px !important; }

.m-35 {
margin: 35px !important; }

.m-40 {
margin: 40px !important; }

.m-45 {
margin: 45px !important; }

.m-50 {
margin: 50px !important; }

.m-55 {
margin: 55px !important; }

.m-60 {
margin: 60px !important; }

.m-65 {
margin: 65px !important; }

.m-70 {
margin: 70px !important; }

.m-75 {
margin: 75px !important; }

.m-80 {
margin: 80px !important; }

.m-85 {
margin: 85px !important; }

.m-90 {
margin: 90px !important; }

.m-95 {
margin: 95px !important; }

.m-100 {
margin: 100px !important; }

.m-l-0 {
margin-left: 0px !important; }

.m-l-5 {
margin-left: 5px !important; }

.m-l-10 {
margin-left: 10px !important; }

.m-l-15 {
margin-left: 15px !important; }

.m-l-20 {
margin-left: 20px !important; }

.m-l-25 {
margin-left: 25px !important; }

.m-l-30 {
margin-left: 30px !important; }

.m-l-35 {
margin-left: 35px !important; }

.m-l-40 {
margin-left: 40px !important; }

.m-l-45 {
margin-left: 45px !important; }

.m-l-50 {
margin-left: 50px !important; }

.m-l-55 {
margin-left: 55px !important; }

.m-l-60 {
margin-left: 60px !important; }

.m-l-65 {
margin-left: 65px !important; }

.m-l-70 {
margin-left: 70px !important; }

.m-l-75 {
margin-left: 75px !important; }

.m-l-80 {
margin-left: 80px !important; }

.m-l-85 {
margin-left: 85px !important; }

.m-l-90 {
margin-left: 90px !important; }

.m-l-95 {
margin-left: 95px !important; }

.m-l-100 {
margin-left: 100px !important; }

.m-r-0 {
margin-right: 0px !important; }

.m-r-5 {
margin-right: 5px !important; }

.m-r-10 {
margin-right: 10px !important; }

.m-r-15 {
margin-right: 15px !important; }

.m-r-20 {
margin-right: 20px !important; }

.m-r-25 {
margin-right: 25px !important; }

.m-r-30 {
margin-right: 30px !important; }

.m-r-35 {
margin-right: 35px !important; }

.m-r-40 {
margin-right: 40px !important; }

.m-r-45 {
margin-right: 45px !important; }

.m-r-50 {
margin-right: 50px !important; }

.m-r-55 {
margin-right: 55px !important; }

.m-r-60 {
margin-right: 60px !important; }

.m-r-65 {
margin-right: 65px !important; }

.m-r-70 {
margin-right: 70px !important; }

.m-r-75 {
margin-right: 75px !important; }

.m-r-80 {
margin-right: 80px !important; }

.m-r-85 {
margin-right: 85px !important; }

.m-r-90 {
margin-right: 90px !important; }

.m-r-95 {
margin-right: 95px !important; }

.m-r-100 {
margin-right: 100px !important; }

.m-t-0 {
margin-top: 0px !important; }

.m-t-5 {
margin-top: 5px !important; }

.m-t-10 {
margin-top: 10px !important; }

.m-t-15 {
margin-top: 15px !important; }

.m-t-20 {
margin-top: 20px !important; }

.m-t-25 {
margin-top: 25px !important; }

.m-t-30 {
margin-top: 30px !important; }

.m-t-35 {
margin-top: 35px !important; }

.m-t-40 {
margin-top: 40px !important; }

.m-t-45 {
margin-top: 45px !important; }

.m-t-50 {
margin-top: 50px !important; }

.m-t-55 {
margin-top: 55px !important; }

.m-t-60 {
margin-top: 60px !important; }

.m-t-65 {
margin-top: 65px !important; }

.m-t-70 {
margin-top: 70px !important; }

.m-t-75 {
margin-top: 75px !important; }

.m-t-80 {
margin-top: 80px !important; }

.m-t-85 {
margin-top: 85px !important; }

.m-t-90 {
margin-top: 90px !important; }

.m-t-95 {
margin-top: 95px !important; }

.m-t-100 {
margin-top: 100px !important; }

.m-b-0 {
margin-bottom: 0px !important; }

.m-b-5 {
margin-bottom: 5px !important; }

.m-b-10 {
margin-bottom: 10px !important; }

.m-b-15 {
margin-bottom: 15px !important; }

.m-b-20 {
margin-bottom: 20px !important; }

.m-b-25 {
margin-bottom: 25px !important; }

.m-b-30 {
margin-bottom: 30px !important; }

.m-b-35 {
margin-bottom: 35px !important; }

.m-b-40 {
margin-bottom: 40px !important; }

.m-b-45 {
margin-bottom: 45px !important; }

.m-b-50 {
margin-bottom: 50px !important; }

.m-b-55 {
margin-bottom: 55px !important; }

.m-b-60 {
margin-bottom: 60px !important; }

.m-b-65 {
margin-bottom: 65px !important; }

.m-b-70 {
margin-bottom: 70px !important; }

.m-b-75 {
margin-bottom: 75px !important; }

.m-b-80 {
margin-bottom: 80px !important; }

.m-b-85 {
margin-bottom: 85px !important; }

.m-b-90 {
margin-bottom: 90px !important; }

.m-b-95 {
margin-bottom: 95px !important; }

.m-b-100 {
margin-bottom: 100px !important; }

.p-0 {
padding: 0px !important; }

.p-5 {
padding: 5px !important; }

.p-10 {
padding: 10px !important; }

.p-15 {
padding: 15px !important; }

.p-20 {
padding: 20px !important; }

.p-25 {
padding: 25px !important; }

.p-30 {
padding: 30px !important; }

.p-35 {
padding: 35px !important; }

.p-40 {
padding: 40px !important; }

.p-45 {
padding: 45px !important; }

.p-50 {
padding: 50px !important; }

.p-55 {
padding: 55px !important; }

.p-60 {
padding: 60px !important; }

.p-65 {
padding: 65px !important; }

.p-70 {
padding: 70px !important; }

.p-75 {
padding: 75px !important; }

.p-80 {
padding: 80px !important; }

.p-85 {
padding: 85px !important; }

.p-90 {
padding: 90px !important; }

.p-95 {
padding: 95px !important; }

.p-100 {
padding: 100px !important; }

.p-l-0 {
padding-left: 0px !important; }

.p-l-5 {
padding-left: 5px !important; }

.p-l-10 {
padding-left: 10px !important; }

.p-l-15 {
padding-left: 15px !important; }

.p-l-20 {
padding-left: 20px !important; }

.p-l-25 {
padding-left: 25px !important; }

.p-l-30 {
padding-left: 30px !important; }

.p-l-35 {
padding-left: 35px !important; }

.p-l-40 {
padding-left: 40px !important; }

.p-l-45 {
padding-left: 45px !important; }

.p-l-50 {
padding-left: 50px !important; }

.p-l-55 {
padding-left: 55px !important; }

.p-l-60 {
padding-left: 60px !important; }

.p-l-65 {
padding-left: 65px !important; }

.p-l-70 {
padding-left: 70px !important; }

.p-l-75 {
padding-left: 75px !important; }

.p-l-80 {
padding-left: 80px !important; }

.p-l-85 {
padding-left: 85px !important; }

.p-l-90 {
padding-left: 90px !important; }

.p-l-95 {
padding-left: 95px !important; }

.p-l-100 {
padding-left: 100px !important; }

.p-r-0 {
padding-right: 0px !important; }

.p-r-5 {
padding-right: 5px !important; }

.p-r-10 {
padding-right: 10px !important; }

.p-r-15 {
padding-right: 15px !important; }

.p-r-20 {
padding-right: 20px !important; }

.p-r-25 {
padding-right: 25px !important; }

.p-r-30 {
padding-right: 30px !important; }

.p-r-35 {
padding-right: 35px !important; }

.p-r-40 {
padding-right: 40px !important; }

.p-r-45 {
padding-right: 45px !important; }

.p-r-50 {
padding-right: 50px !important; }

.p-r-55 {
padding-right: 55px !important; }

.p-r-60 {
padding-right: 60px !important; }

.p-r-65 {
padding-right: 65px !important; }

.p-r-70 {
padding-right: 70px !important; }

.p-r-75 {
padding-right: 75px !important; }

.p-r-80 {
padding-right: 80px !important; }

.p-r-85 {
padding-right: 85px !important; }

.p-r-90 {
padding-right: 90px !important; }

.p-r-95 {
padding-right: 95px !important; }

.p-r-100 {
padding-right: 100px !important; }

.p-t-0 {
padding-top: 0px !important; }

.p-t-5 {
padding-top: 5px !important; }

.p-t-10 {
padding-top: 10px !important; }

.p-t-15 {
padding-top: 15px !important; }

.p-t-20 {
padding-top: 20px !important; }

.p-t-25 {
padding-top: 25px !important; }

.p-t-30 {
padding-top: 30px !important; }

.p-t-35 {
padding-top: 35px !important; }

.p-t-40 {
padding-top: 40px !important; }

.p-t-45 {
padding-top: 45px !important; }

.p-t-50 {
padding-top: 50px !important; }

.p-t-55 {
padding-top: 55px !important; }

.p-t-60 {
padding-top: 60px !important; }

.p-t-65 {
padding-top: 65px !important; }

.p-t-70 {
padding-top: 70px !important; }

.p-t-75 {
padding-top: 75px !important; }

.p-t-80 {
padding-top: 80px !important; }

.p-t-85 {
padding-top: 85px !important; }

.p-t-90 {
padding-top: 90px !important; }

.p-t-95 {
padding-top: 95px !important; }

.p-t-100 {
padding-top: 100px !important; }

.p-b-0 {
padding-bottom: 0px !important; }

.p-b-5 {
padding-bottom: 5px !important; }

.p-b-10 {
padding-bottom: 10px !important; }

.p-b-15 {
padding-bottom: 15px !important; }

.p-b-20 {
padding-bottom: 20px !important; }

.p-b-25 {
padding-bottom: 25px !important; }

.p-b-30 {
padding-bottom: 30px !important; }

.p-b-35 {
padding-bottom: 35px !important; }

.p-b-40 {
padding-bottom: 40px !important; }

.p-b-45 {
padding-bottom: 45px !important; }

.p-b-50 {
padding-bottom: 50px !important; }

.p-b-55 {
padding-bottom: 55px !important; }

.p-b-60 {
padding-bottom: 60px !important; }

.p-b-65 {
padding-bottom: 65px !important; }

.p-b-70 {
padding-bottom: 70px !important; }

.p-b-75 {
padding-bottom: 75px !important; }

.p-b-80 {
padding-bottom: 80px !important; }

.p-b-85 {
padding-bottom: 85px !important; }

.p-b-90 {
padding-bottom: 90px !important; }

.p-b-95 {
padding-bottom: 95px !important; }

.p-b-100 {
padding-bottom: 100px !important; }

.w-0 {
width: 0px !important; }

.min-w-0 {
min-width: 0px !important; }

.max-w-0 {
max-width: 0px !important; }

.w-10 {
width: 10px !important; }

.min-w-10 {
min-width: 10px !important; }

.max-w-10 {
max-width: 10px !important; }

.w-20 {
width: 20px !important; }

.min-w-20 {
min-width: 20px !important; }

.max-w-20 {
max-width: 20px !important; }

.w-30 {
width: 30px !important; }

.min-w-30 {
min-width: 30px !important; }

.max-w-30 {
max-width: 30px !important; }

.w-40 {
width: 40px !important; }

.min-w-40 {
min-width: 40px !important; }

.max-w-40 {
max-width: 40px !important; }

.w-50 {
width: 50px !important; }

.min-w-50 {
min-width: 50px !important; }

.max-w-50 {
max-width: 50px !important; }

.w-60 {
width: 60px !important; }

.min-w-60 {
min-width: 60px !important; }

.max-w-60 {
max-width: 60px !important; }

.w-70 {
width: 70px !important; }

.min-w-70 {
min-width: 70px !important; }

.max-w-70 {
max-width: 70px !important; }

.w-80 {
width: 80px !important; }

.min-w-80 {
min-width: 80px !important; }

.max-w-80 {
max-width: 80px !important; }

.w-90 {
width: 90px !important; }

.min-w-90 {
min-width: 90px !important; }

.max-w-90 {
max-width: 90px !important; }

.w-100 {
width: 100px !important; }

.min-w-100 {
min-width: 100px !important; }

.max-w-100 {
max-width: 100px !important; }

.w-150 {
width: 150px !important; }

.min-w-150 {
min-width: 150px !important; }

.max-w-150 {
max-width: 150px !important; }

.w-200 {
width: 200px !important; }

.min-w-200 {
min-width: 200px !important; }

.max-w-200 {
max-width: 200px !important; }

.w-250 {
width: 250px !important; }

.min-w-250 {
min-width: 250px !important; }

.max-w-250 {
max-width: 250px !important; }

.w-300 {
width: 300px !important; }

.min-w-300 {
min-width: 300px !important; }

.max-w-300 {
max-width: 300px !important; }

.w-350 {
width: 350px !important; }

.min-w-350 {
min-width: 350px !important; }

.max-w-350 {
max-width: 350px !important; }

.w-400 {
width: 400px !important; }

.min-w-400 {
min-width: 400px !important; }

.max-w-400 {
max-width: 400px !important; }

.w-450 {
width: 450px !important; }

.min-w-450 {
min-width: 450px !important; }

.max-w-450 {
max-width: 450px !important; }

.w-500 {
width: 500px !important; }

.min-w-500 {
min-width: 500px !important; }

.max-w-500 {
max-width: 500px !important; }

.text-bold {
font-weight: bold !important; }

.text-left {
text-align: left !important; }

.text-center {
text-align: center !important; }

.text-right {
text-align: right !important; }

.text-justify {
text-align: justify !important; }

.text-lowercase {
text-transform: lowercase !important; }

.text-uppercase {
text-transform: uppercase !important; }

.text-capitalize {
text-transform: capitalize !important; }

.f-w-300 {
font-weight: 300 !important; }

.f-w-400 {
font-weight: 400 !important; }

.f-w-500 {
font-weight: 500 !important; }

.f-w-600 {
font-weight: 600 !important; }

.f-w-700 {
font-weight: 700 !important; }

.f-w-800 {
font-weight: 800 !important; }

.f-w-900 {
font-weight: 900 !important; }

.f-s-10 {
font-size: 10px !important; }

.f-s-11 {
font-size: 11px !important; }

.f-s-12 {
font-size: 12px !important; }

.f-s-13 {
font-size: 13px !important; }

.f-s-14 {
font-size: 14px !important; }

.f-s-15 {
font-size: 15px !important; }

.f-s-16 {
font-size: 16px !important; }

.f-s-17 {
font-size: 17px !important; }

.f-s-18 {
font-size: 18px !important; }

.f-s-19 {
font-size: 19px !important; }

.f-s-20 {
font-size: 20px !important; }

.f-s-21 {
font-size: 21px !important; }

.f-s-22 {
font-size: 22px !important; }

.f-s-23 {
font-size: 23px !important; }

.f-s-24 {
font-size: 24px !important; }

.text-xs {
font-size: 0.8rem !important; }

.text-sm {
font-size: 0.95rem !important; }

.text-default {
font-size: 1rem !important; }

.text-lg {
font-size: 1.2rem !important; }

.flex-0 {
flex: 0 0 0px !important; }

.flex-10 {
flex: 0 0 10px !important; }

.flex-20 {
flex: 0 0 20px !important; }

.flex-30 {
flex: 0 0 30px !important; }

.flex-40 {
flex: 0 0 40px !important; }

.flex-50 {
flex: 0 0 50px !important; }

.flex-60 {
flex: 0 0 60px !important; }

.flex-70 {
flex: 0 0 70px !important; }

.flex-80 {
flex: 0 0 80px !important; }

.flex-90 {
flex: 0 0 90px !important; }

.flex-100 {
flex: 0 0 100px !important; }

.flex-110 {
flex: 0 0 110px !important; }

.flex-120 {
flex: 0 0 120px !important; }

.flex-130 {
flex: 0 0 130px !important; }

.flex-140 {
flex: 0 0 140px !important; }

.flex-150 {
flex: 0 0 150px !important; }

.flex-160 {
flex: 0 0 160px !important; }

.flex-170 {
flex: 0 0 170px !important; }

.flex-180 {
flex: 0 0 180px !important; }

.flex-190 {
flex: 0 0 190px !important; }

.flex-200 {
flex: 0 0 200px !important; }

[data-layout="default-sidebar"] .navbar {
position: absolute;
top: 0;
left: 0;
right: 0;
z-index: 1030;
width: 100%;
padding-left: 0;
padding-right: 20px;
transform: translate3d(0, 0, 0);
box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
[data-layout="default-sidebar"] .navbar .logo {
padding-left: 20px;
width: 280px; }

[data-layout="default-sidebar"] .main {
padding-top: 65px;
padding-left: 300px;
padding-right: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"] .main .jumbotron {
width: 100%;
margin: 0; }
[data-layout="default-sidebar"] .main > .row {
margin-bottom: 20px;
padding-bottom: 0; }

[data-layout="default-sidebar"] .left-sidebar-placeholder {
position: fixed;
top: 0;
bottom: 0;
left: 0;
height: calc(100%);
width: 280px;
z-index: 1028;
transform: translate3d(0, 0, 0);
box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

[data-layout="default-sidebar"] .left-sidebar {
position: absolute;
top: 70px;
bottom: 0;
left: 0;
min-height: 0;
height: calc(100%);
width: 280px;
z-index: 1029;
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"] .left-sidebar .wrapper {
position: absolute;
top: 0;
left: 0;
bottom: 0;
min-height: 0;
height: calc(100%);
width: 300px; }
[data-layout="default-sidebar"] .left-sidebar .wrapper .content {
min-height: 0;
height: calc(100%);
width: 280px;
margin-bottom: 20px; }

@media (max-width: 575px) {
[data-layout="default-sidebar"][data-collapsed] .navbar, [data-layout="default-sidebar"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo {
width: 80px; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo i, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo i {
margin: 0; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo .title, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo .title {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .navbar .nav-inline-1,
[data-layout="default-sidebar"][data-collapsed] .navbar .nav-inline-2,
[data-layout="default-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .nav-inline-1,
[data-layout="default-sidebar"][data-collapsed="false"] .navbar .nav-inline-2,
[data-layout="default-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .main, [data-layout="default-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .main .shipping-status, [data-layout="default-sidebar"][data-collapsed="false"] .main .shipping-status {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .main .jumbotron .jumbotron-actions, [data-layout="default-sidebar"][data-collapsed="false"] .main .jumbotron .jumbotron-actions {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (max-width: 767px) {
[data-layout="default-sidebar"][data-collapsed] .navbar, [data-layout="default-sidebar"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo {
width: 80px; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo i, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo i {
margin: 0; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo .title, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo .title {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .navbar .nav-inline-2,
[data-layout="default-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .nav-inline-2,
[data-layout="default-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .main, [data-layout="default-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (max-width: 991px) {
[data-layout="default-sidebar"][data-collapsed] .navbar, [data-layout="default-sidebar"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo {
width: 80px; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo i, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo i {
margin: 0; }
[data-layout="default-sidebar"][data-collapsed] .navbar .logo .title, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .logo .title {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .main, [data-layout="default-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (max-width: 1199px) {
[data-layout="default-sidebar"][data-collapsed] .navbar, [data-layout="default-sidebar"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="default-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="default-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="default-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="default-sidebar"][data-collapsed] .main, [data-layout="default-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed] .left-sidebar, [data-layout="default-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="default-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (min-width: 1200px) {
[data-layout="default-sidebar"] .navbar .nav-toggle-layout-2 {
display: none; } }

[data-layout="collapsed-sidebar"] .navbar {
position: absolute;
top: 0;
left: 0;
right: 0;
z-index: 1030;
width: calc(100% - 80);
padding-left: 0;
padding-right: 20px;
transform: translate3d(0, 0, 0);
box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
[data-layout="collapsed-sidebar"] .navbar .logo {
padding-left: 20px;
width: 80px; }
[data-layout="collapsed-sidebar"] .navbar .logo .title {
display: none; }

[data-layout="collapsed-sidebar"] .main {
padding-top: 90px;
padding-left: 100px;
padding-right: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"] .main .jumbotron {
width: 100%;
margin: 0; }
[data-layout="collapsed-sidebar"] .main > .row {
margin-bottom: 20px;
padding-bottom: 0; }

[data-layout="collapsed-sidebar"] .left-sidebar-placeholder {
position: fixed;
top: 0;
bottom: 0;
left: 0;
height: calc(100%);
width: 80px;
z-index: 1028;
transform: translate3d(0, 0, 0);
box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

[data-layout="collapsed-sidebar"] .left-sidebar {
position: absolute;
top: 70px;
bottom: 0;
left: 0;
height: calc(100%);
width: 80px;
z-index: 1029;
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"] .left-sidebar .wrapper {
position: absolute;
top: 0;
left: 0;
bottom: 0;
height: calc(100%);
width: 80px; }
[data-layout="collapsed-sidebar"] .left-sidebar .wrapper .content {
height: calc(100%);
width: 80px; }
[data-layout="collapsed-sidebar"] .left-sidebar .wrapper .content .sidebar-widget-1,
[data-layout="collapsed-sidebar"] .left-sidebar .wrapper .content .sidebar-widget-2 {
display: none; }

@media (max-width: 575px) {
[data-layout="collapsed-sidebar"][data-collapsed] .navbar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar {
padding-right: 0; }
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .nav-inline-1,
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .nav-inline-2,
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .nav-inline-1,
[data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .nav-inline-2,
[data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="collapsed-sidebar"][data-collapsed] .main, [data-layout="collapsed-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .main .shipping-status, [data-layout="collapsed-sidebar"][data-collapsed="false"] .main .shipping-status {
display: none; }
[data-layout="collapsed-sidebar"][data-collapsed] .main .jumbotron .jumbotron-actions, [data-layout="collapsed-sidebar"][data-collapsed="false"] .main .jumbotron .jumbotron-actions {
display: none; }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (max-width: 767px) {
[data-layout="collapsed-sidebar"][data-collapsed] .navbar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar {
padding-right: 0; }
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .nav-inline-2,
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .nav-inline-2,
[data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="collapsed-sidebar"][data-collapsed] .main, [data-layout="collapsed-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (max-width: 991px) {
[data-layout="collapsed-sidebar"][data-collapsed] .navbar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar {
padding-right: 0; }
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="collapsed-sidebar"][data-collapsed] .main, [data-layout="collapsed-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (max-width: 1199px) {
[data-layout="collapsed-sidebar"][data-collapsed] .navbar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar {
padding-right: 0; }
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed] .navbar .navbar-search-form, [data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .nav-toggle-layout-1,
[data-layout="collapsed-sidebar"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="collapsed-sidebar"][data-collapsed] .main, [data-layout="collapsed-sidebar"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar-placeholder, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar-placeholder {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed] .left-sidebar, [data-layout="collapsed-sidebar"][data-collapsed="false"] .left-sidebar {
transform: translate3d(-280px, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar-placeholder {
transform: translate3d(0, 0, 0); }
[data-layout="collapsed-sidebar"][data-collapsed="true"] .left-sidebar {
transform: translate3d(0, 0, 0); } }

@media (min-width: 1200px) {
[data-layout="collapsed-sidebar"] .navbar .nav-toggle-layout-2 {
display: none; } }

[data-layout="top-navigation-1"] .navbar {
position: absolute;
top: 0;
left: 0;
right: 0;
z-index: 1032;
width: 100%;
padding-left: 0;
padding-right: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="top-navigation-1"] .navbar .logo {
padding-left: 20px;
width: 280px; }

[data-layout="top-navigation-1"] .top-navigation {
position: absolute;
top: 70px;
left: 0;
right: 0;
z-index: 1031;
width: 100%;
padding-left: 0;
padding-right: 20px;
transform: translate3d(0, 0, 0);
box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

[data-layout="top-navigation-1"] .main {
padding-top: 160px;
padding-left: 20px;
padding-right: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="top-navigation-1"] .main .jumbotron {
width: 100%;
margin: 0; }
[data-layout="top-navigation-1"] .main > .row {
margin-bottom: 20px;
padding-bottom: 0; }

@media (max-width: 575px) {
[data-layout="top-navigation-1"][data-collapsed] .navbar, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="top-navigation-1"][data-collapsed] .navbar .nav-inline-1,
[data-layout="top-navigation-1"][data-collapsed] .navbar .nav-inline-2,
[data-layout="top-navigation-1"][data-collapsed] .navbar .navbar-search-form, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar .nav-inline-1,
[data-layout="top-navigation-1"][data-collapsed="false"] .navbar .nav-inline-2,
[data-layout="top-navigation-1"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="top-navigation-1"][data-collapsed] .main, [data-layout="top-navigation-1"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); }
[data-layout="top-navigation-1"][data-collapsed] .main .shipping-status, [data-layout="top-navigation-1"][data-collapsed="false"] .main .shipping-status {
display: none; }
[data-layout="top-navigation-1"][data-collapsed] .main .jumbotron .jumbotron-actions, [data-layout="top-navigation-1"][data-collapsed="false"] .main .jumbotron .jumbotron-actions {
display: none; } }

@media (max-width: 767px) {
[data-layout="top-navigation-1"][data-collapsed] .navbar, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="top-navigation-1"][data-collapsed] .navbar .nav-inline-2,
[data-layout="top-navigation-1"][data-collapsed] .navbar .navbar-search-form, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar .nav-inline-2,
[data-layout="top-navigation-1"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="top-navigation-1"][data-collapsed] .main, [data-layout="top-navigation-1"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); } }

@media (max-width: 991px) {
[data-layout="top-navigation-1"][data-collapsed] .navbar, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="top-navigation-1"][data-collapsed] .navbar .navbar-search-form, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="top-navigation-1"][data-collapsed] .main, [data-layout="top-navigation-1"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); } }

@media (max-width: 1199px) {
[data-layout="top-navigation-1"][data-collapsed] .navbar, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar {
padding-left: 0;
padding-right: 0;
left: 0; }
[data-layout="top-navigation-1"][data-collapsed] .navbar .navbar-search-form, [data-layout="top-navigation-1"][data-collapsed="false"] .navbar .navbar-search-form {
display: none; }
[data-layout="top-navigation-1"][data-collapsed] .main, [data-layout="top-navigation-1"][data-collapsed="false"] .main {
padding-left: 20px;
transform: translate3d(0, 0, 0); } }

/* added Joel */
[data-layout="login-layout"] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

[data-layout="error-layout"] {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* background: url(/assets/images/background.svg) no-repeat center center fixed; */
  background-size: cover; 
}

body,
html,
#root {
height: 100%;
min-height: 100%; }

body {
font-family: "Roboto", sans-serif;
font-weight: normal;
-webkit-font-smoothing: antialiased;
-moz-font-smoothing: antialiased;
-ms-font-smoothing: antialiased;
font-smoothing: antialiased;
padding: 0;
margin: 0;
overflow-x: hidden;
position: relative;
font-size: 0.875rem; }

::-webkit-scrollbar {
display: none;
width: 0 !important; }

html,
body,
#root {
background-color: #ffffff;
color: #212121; }

[data-navbar="light"] a,
[data-navbar="light"] .btn,
[data-sidebar="light"] a,
[data-sidebar="light"] .btn,
[data-background="light"] a,
[data-background="light"] .btn {
color: #212121; }

[data-navbar="dark"] a,
[data-navbar="dark"] .btn,
[data-sidebar="dark"] a,
[data-sidebar="dark"] .btn,
[data-background="dark"] a,
[data-background="dark"] .btn {
color: #ffffff; }

[data-navbar="primary"] a,
[data-navbar="primary"] .btn,
[data-sidebar="primary"] a,
[data-sidebar="primary"] .btn,
[data-background="primary"] a,
[data-background="primary"] .btn {
color: #ffffff; }

input,
select {
font-family: "Roboto", sans-serif; }

.hidden {
display: none; }

.no-before:before {
display: none !important; }

.no-after:after {
display: none !important; }

.loading .pace {
display: none; }

.separator {
flex: 1; }

.left-sidebar-backdrop {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: none;
z-index: 1035;
opacity: 0;
background: #000;
transition: opacity 0.25s ease-in-out; }
.left-sidebar-backdrop.fade {
display: block; }
.left-sidebar-backdrop.in {
opacity: 0.35; }

.right-sidebar-backdrop {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: none;
z-index: 1034;
opacity: 0;
background: #000;
transition: opacity 0.25s ease-in-out; }
.right-sidebar-backdrop.fade {
display: block; }
.right-sidebar-backdrop.in {
opacity: 0.35; }

.no-bg,
.bg-transparent {
background-color: transparent !important; }

.bg-blue {
background-color: #0288d1 !important; }

.bg-hover-blue:hover, .bg-hover-blue:active, .bg-hover-blue:focus {
background-color: #0288d1 !important; }

.color-blue {
color: #0288d1 !important; }

.color-hover-blue:hover, .color-hover-blue:active, .color-hover-blue:focus {
color: #0288d1 !important; }

.border-color-blue {
color: #0288d1 !important; }

.border-color-hover-blue:hover, .border-color-hover-blue:active, .border-color-hover-blue:focus {
color: #0288d1 !important; }

.bg-indigo {
background-color: #303f9f !important; }

.bg-hover-indigo:hover, .bg-hover-indigo:active, .bg-hover-indigo:focus {
background-color: #303f9f !important; }

.color-indigo {
color: #303f9f !important; }

.color-hover-indigo:hover, .color-hover-indigo:active, .color-hover-indigo:focus {
color: #303f9f !important; }

.border-color-indigo {
color: #303f9f !important; }

.border-color-hover-indigo:hover, .border-color-hover-indigo:active, .border-color-hover-indigo:focus {
color: #303f9f !important; }

.bg-purple {
background-color: #7b1fa2 !important; }

.bg-hover-purple:hover, .bg-hover-purple:active, .bg-hover-purple:focus {
background-color: #7b1fa2 !important; }

.color-purple {
color: #7b1fa2 !important; }

.color-hover-purple:hover, .color-hover-purple:active, .color-hover-purple:focus {
color: #7b1fa2 !important; }

.border-color-purple {
color: #7b1fa2 !important; }

.border-color-hover-purple:hover, .border-color-hover-purple:active, .border-color-hover-purple:focus {
color: #7b1fa2 !important; }

.bg-pink {
background-color: #c2185b !important; }

.bg-hover-pink:hover, .bg-hover-pink:active, .bg-hover-pink:focus {
background-color: #c2185b !important; }

.color-pink {
color: #c2185b !important; }

.color-hover-pink:hover, .color-hover-pink:active, .color-hover-pink:focus {
color: #c2185b !important; }

.border-color-pink {
color: #c2185b !important; }

.border-color-hover-pink:hover, .border-color-hover-pink:active, .border-color-hover-pink:focus {
color: #c2185b !important; }

.bg-red {
background-color: #d32f2f !important; }

.bg-hover-red:hover, .bg-hover-red:active, .bg-hover-red:focus {
background-color: #d32f2f !important; }

.color-red {
color: #d32f2f !important; }

.color-hover-red:hover, .color-hover-red:active, .color-hover-red:focus {
color: #d32f2f !important; }

.border-color-red {
color: #d32f2f !important; }

.border-color-hover-red:hover, .border-color-hover-red:active, .border-color-hover-red:focus {
color: #d32f2f !important; }

.bg-orange {
background-color: #f57c00 !important; }

.bg-hover-orange:hover, .bg-hover-orange:active, .bg-hover-orange:focus {
background-color: #f57c00 !important; }

.color-orange {
color: #f57c00 !important; }

.color-hover-orange:hover, .color-hover-orange:active, .color-hover-orange:focus {
color: #f57c00 !important; }

.border-color-orange {
color: #f57c00 !important; }

.border-color-hover-orange:hover, .border-color-hover-orange:active, .border-color-hover-orange:focus {
color: #f57c00 !important; }

.bg-yellow {
background-color: #ffa000 !important; }

.bg-hover-yellow:hover, .bg-hover-yellow:active, .bg-hover-yellow:focus {
background-color: #ffa000 !important; }

.color-yellow {
color: #ffa000 !important; }

.color-hover-yellow:hover, .color-hover-yellow:active, .color-hover-yellow:focus {
color: #ffa000 !important; }

.border-color-yellow {
color: #ffa000 !important; }

.border-color-hover-yellow:hover, .border-color-hover-yellow:active, .border-color-hover-yellow:focus {
color: #ffa000 !important; }

.bg-green {
background-color: #388e3c !important; }

.bg-hover-green:hover, .bg-hover-green:active, .bg-hover-green:focus {
background-color: #388e3c !important; }

.color-green {
color: #388e3c !important; }

.color-hover-green:hover, .color-hover-green:active, .color-hover-green:focus {
color: #388e3c !important; }

.border-color-green {
color: #388e3c !important; }

.border-color-hover-green:hover, .border-color-hover-green:active, .border-color-hover-green:focus {
color: #388e3c !important; }

.bg-teal {
background-color: #00796b !important; }

.bg-hover-teal:hover, .bg-hover-teal:active, .bg-hover-teal:focus {
background-color: #00796b !important; }

.color-teal {
color: #00796b !important; }

.color-hover-teal:hover, .color-hover-teal:active, .color-hover-teal:focus {
color: #00796b !important; }

.border-color-teal {
color: #00796b !important; }

.border-color-hover-teal:hover, .border-color-hover-teal:active, .border-color-hover-teal:focus {
color: #00796b !important; }

.bg-cyan {
background-color: #0097a7 !important; }

.bg-hover-cyan:hover, .bg-hover-cyan:active, .bg-hover-cyan:focus {
background-color: #0097a7 !important; }

.color-cyan {
color: #0097a7 !important; }

.color-hover-cyan:hover, .color-hover-cyan:active, .color-hover-cyan:focus {
color: #0097a7 !important; }

.border-color-cyan {
color: #0097a7 !important; }

.border-color-hover-cyan:hover, .border-color-hover-cyan:active, .border-color-hover-cyan:focus {
color: #0097a7 !important; }

.bg-white {
background-color: #fff !important; }

.bg-hover-white:hover, .bg-hover-white:active, .bg-hover-white:focus {
background-color: #fff !important; }

.color-white {
color: #fff !important; }

.color-hover-white:hover, .color-hover-white:active, .color-hover-white:focus {
color: #fff !important; }

.border-color-white {
color: #fff !important; }

.border-color-hover-white:hover, .border-color-hover-white:active, .border-color-hover-white:focus {
color: #fff !important; }

.bg-gray {
background-color: #9e9e9e !important; }

.bg-hover-gray:hover, .bg-hover-gray:active, .bg-hover-gray:focus {
background-color: #9e9e9e !important; }

.color-gray {
color: #9e9e9e !important; }

.color-hover-gray:hover, .color-hover-gray:active, .color-hover-gray:focus {
color: #9e9e9e !important; }

.border-color-gray {
color: #9e9e9e !important; }

.border-color-hover-gray:hover, .border-color-hover-gray:active, .border-color-hover-gray:focus {
color: #9e9e9e !important; }

.bg-gray-dark {
background-color: #212121 !important; }

.bg-hover-gray-dark:hover, .bg-hover-gray-dark:active, .bg-hover-gray-dark:focus {
background-color: #212121 !important; }

.color-gray-dark {
color: #212121 !important; }

.color-hover-gray-dark:hover, .color-hover-gray-dark:active, .color-hover-gray-dark:focus {
color: #212121 !important; }

.border-color-gray-dark {
color: #212121 !important; }

.border-color-hover-gray-dark:hover, .border-color-hover-gray-dark:active, .border-color-hover-gray-dark:focus {
color: #212121 !important; }

.bg-light {
background-color: #ffffff !important; }

.bg-hover-light:hover, .bg-hover-light:active, .bg-hover-light:focus {
background-color: #ffffff !important; }

.color-light {
color: #ffffff !important; }

.color-hover-light:hover, .color-hover-light:active, .color-hover-light:focus {
color: #ffffff !important; }

.border-color-light {
color: #ffffff !important; }

.border-color-hover-light:hover, .border-color-hover-light:active, .border-color-hover-light:focus {
color: #ffffff !important; }

.bg-dark {
background-color: #212121 !important; }

.bg-hover-dark:hover, .bg-hover-dark:active, .bg-hover-dark:focus {
background-color: #212121 !important; }

.color-dark {
color: #212121 !important; }

.color-hover-dark:hover, .color-hover-dark:active, .color-hover-dark:focus {
color: #212121 !important; }

.border-color-dark {
color: #212121 !important; }

.border-color-hover-dark:hover, .border-color-hover-dark:active, .border-color-hover-dark:focus {
color: #212121 !important; }

.bg-default {
background-color: #212121 !important; }

.bg-hover-default:hover, .bg-hover-default:active, .bg-hover-default:focus {
background-color: #212121 !important; }

.color-default {
color: #212121 !important; }

.color-hover-default:hover, .color-hover-default:active, .color-hover-default:focus {
color: #212121 !important; }

.border-color-default {
color: #212121 !important; }

.border-color-hover-default:hover, .border-color-hover-default:active, .border-color-hover-default:focus {
color: #212121 !important; }

.bg-primary {
background-color: #303f9f !important; }

.bg-hover-primary:hover, .bg-hover-primary:active, .bg-hover-primary:focus {
background-color: #303f9f !important; }

.color-primary {
color: #303f9f !important; }

.color-hover-primary:hover, .color-hover-primary:active, .color-hover-primary:focus {
color: #303f9f !important; }

.border-color-primary {
color: #303f9f !important; }

.border-color-hover-primary:hover, .border-color-hover-primary:active, .border-color-hover-primary:focus {
color: #303f9f !important; }

.bg-secondary {
background-color: #7b1fa2 !important; }

.bg-hover-secondary:hover, .bg-hover-secondary:active, .bg-hover-secondary:focus {
background-color: #7b1fa2 !important; }

.color-secondary {
color: #7b1fa2 !important; }

.color-hover-secondary:hover, .color-hover-secondary:active, .color-hover-secondary:focus {
color: #7b1fa2 !important; }

.border-color-secondary {
color: #7b1fa2 !important; }

.border-color-hover-secondary:hover, .border-color-hover-secondary:active, .border-color-hover-secondary:focus {
color: #7b1fa2 !important; }

.bg-info {
background-color: #0288d1 !important; }

.bg-hover-info:hover, .bg-hover-info:active, .bg-hover-info:focus {
background-color: #0288d1 !important; }

.color-info {
color: #0288d1 !important; }

.color-hover-info:hover, .color-hover-info:active, .color-hover-info:focus {
color: #0288d1 !important; }

.border-color-info {
color: #0288d1 !important; }

.border-color-hover-info:hover, .border-color-hover-info:active, .border-color-hover-info:focus {
color: #0288d1 !important; }

.bg-success {
background-color: #388e3c !important; }

.bg-hover-success:hover, .bg-hover-success:active, .bg-hover-success:focus {
background-color: #388e3c !important; }

.color-success {
color: #388e3c !important; }

.color-hover-success:hover, .color-hover-success:active, .color-hover-success:focus {
color: #388e3c !important; }

.border-color-success {
color: #388e3c !important; }

.border-color-hover-success:hover, .border-color-hover-success:active, .border-color-hover-success:focus {
color: #388e3c !important; }

.bg-warning {
background-color: #ffa000 !important; }

.bg-hover-warning:hover, .bg-hover-warning:active, .bg-hover-warning:focus {
background-color: #ffa000 !important; }

.color-warning {
color: #ffa000 !important; }

.color-hover-warning:hover, .color-hover-warning:active, .color-hover-warning:focus {
color: #ffa000 !important; }

.border-color-warning {
color: #ffa000 !important; }

.border-color-hover-warning:hover, .border-color-hover-warning:active, .border-color-hover-warning:focus {
color: #ffa000 !important; }

.bg-danger {
background-color: #d32f2f !important; }

.bg-hover-danger:hover, .bg-hover-danger:active, .bg-hover-danger:focus {
background-color: #d32f2f !important; }

.color-danger {
color: #d32f2f !important; }

.color-hover-danger:hover, .color-hover-danger:active, .color-hover-danger:focus {
color: #d32f2f !important; }

.border-color-danger {
color: #d32f2f !important; }

.border-color-hover-danger:hover, .border-color-hover-danger:active, .border-color-hover-danger:focus {
color: #d32f2f !important; }

.bg-facebook {
background-color: #365397 !important; }

.bg-hover-facebook:hover, .bg-hover-facebook:active, .bg-hover-facebook:focus {
background-color: #365397 !important; }

.color-facebook {
color: #365397 !important; }

.color-hover-facebook:hover, .color-hover-facebook:active, .color-hover-facebook:focus {
color: #365397 !important; }

.border-color-facebook {
color: #365397 !important; }

.border-color-hover-facebook:hover, .border-color-hover-facebook:active, .border-color-hover-facebook:focus {
color: #365397 !important; }

.bg-twitter {
background-color: #00a9f1 !important; }

.bg-hover-twitter:hover, .bg-hover-twitter:active, .bg-hover-twitter:focus {
background-color: #00a9f1 !important; }

.color-twitter {
color: #00a9f1 !important; }

.color-hover-twitter:hover, .color-hover-twitter:active, .color-hover-twitter:focus {
color: #00a9f1 !important; }

.border-color-twitter {
color: #00a9f1 !important; }

.border-color-hover-twitter:hover, .border-color-hover-twitter:active, .border-color-hover-twitter:focus {
color: #00a9f1 !important; }

.bg-linkedin {
background-color: #006db3 !important; }

.bg-hover-linkedin:hover, .bg-hover-linkedin:active, .bg-hover-linkedin:focus {
background-color: #006db3 !important; }

.color-linkedin {
color: #006db3 !important; }

.color-hover-linkedin:hover, .color-hover-linkedin:active, .color-hover-linkedin:focus {
color: #006db3 !important; }

.border-color-linkedin {
color: #006db3 !important; }

.border-color-hover-linkedin:hover, .border-color-hover-linkedin:active, .border-color-hover-linkedin:focus {
color: #006db3 !important; }

.bg-apple {
background-color: #737373 !important; }

.bg-hover-apple:hover, .bg-hover-apple:active, .bg-hover-apple:focus {
background-color: #737373 !important; }

.color-apple {
color: #737373 !important; }

.color-hover-apple:hover, .color-hover-apple:active, .color-hover-apple:focus {
color: #737373 !important; }

.border-color-apple {
color: #737373 !important; }

.border-color-hover-apple:hover, .border-color-hover-apple:active, .border-color-hover-apple:focus {
color: #737373 !important; }

.bg-google {
background-color: #4285f4 !important; }

.bg-hover-google:hover, .bg-hover-google:active, .bg-hover-google:focus {
background-color: #4285f4 !important; }

.color-google {
color: #4285f4 !important; }

.color-hover-google:hover, .color-hover-google:active, .color-hover-google:focus {
color: #4285f4 !important; }

.border-color-google {
color: #4285f4 !important; }

.border-color-hover-google:hover, .border-color-hover-google:active, .border-color-hover-google:focus {
color: #4285f4 !important; }

.bg-google-plus {
background-color: #e0452c !important; }

.bg-hover-google-plus:hover, .bg-hover-google-plus:active, .bg-hover-google-plus:focus {
background-color: #e0452c !important; }

.color-google-plus {
color: #e0452c !important; }

.color-hover-google-plus:hover, .color-hover-google-plus:active, .color-hover-google-plus:focus {
color: #e0452c !important; }

.border-color-google-plus {
color: #e0452c !important; }

.border-color-hover-google-plus:hover, .border-color-hover-google-plus:active, .border-color-hover-google-plus:focus {
color: #e0452c !important; }

.bg-youtube {
background-color: #ff3333 !important; }

.bg-hover-youtube:hover, .bg-hover-youtube:active, .bg-hover-youtube:focus {
background-color: #ff3333 !important; }

.color-youtube {
color: #ff3333 !important; }

.color-hover-youtube:hover, .color-hover-youtube:active, .color-hover-youtube:focus {
color: #ff3333 !important; }

.border-color-youtube {
color: #ff3333 !important; }

.border-color-hover-youtube:hover, .border-color-hover-youtube:active, .border-color-hover-youtube:focus {
color: #ff3333 !important; }

.bg-vimeo {
background-color: #48b6ed !important; }

.bg-hover-vimeo:hover, .bg-hover-vimeo:active, .bg-hover-vimeo:focus {
background-color: #48b6ed !important; }

.color-vimeo {
color: #48b6ed !important; }

.color-hover-vimeo:hover, .color-hover-vimeo:active, .color-hover-vimeo:focus {
color: #48b6ed !important; }

.border-color-vimeo {
color: #48b6ed !important; }

.border-color-hover-vimeo:hover, .border-color-hover-vimeo:active, .border-color-hover-vimeo:focus {
color: #48b6ed !important; }

.bg-pinterest {
background-color: #ce1a19 !important; }

.bg-hover-pinterest:hover, .bg-hover-pinterest:active, .bg-hover-pinterest:focus {
background-color: #ce1a19 !important; }

.color-pinterest {
color: #ce1a19 !important; }

.color-hover-pinterest:hover, .color-hover-pinterest:active, .color-hover-pinterest:focus {
color: #ce1a19 !important; }

.border-color-pinterest {
color: #ce1a19 !important; }

.border-color-hover-pinterest:hover, .border-color-hover-pinterest:active, .border-color-hover-pinterest:focus {
color: #ce1a19 !important; }

.bg-yelp {
background-color: #c30f00 !important; }

.bg-hover-yelp:hover, .bg-hover-yelp:active, .bg-hover-yelp:focus {
background-color: #c30f00 !important; }

.color-yelp {
color: #c30f00 !important; }

.color-hover-yelp:hover, .color-hover-yelp:active, .color-hover-yelp:focus {
color: #c30f00 !important; }

.border-color-yelp {
color: #c30f00 !important; }

.border-color-hover-yelp:hover, .border-color-hover-yelp:active, .border-color-hover-yelp:focus {
color: #c30f00 !important; }

.bg-dribbble {
background-color: #ed4584 !important; }

.bg-hover-dribbble:hover, .bg-hover-dribbble:active, .bg-hover-dribbble:focus {
background-color: #ed4584 !important; }

.color-dribbble {
color: #ed4584 !important; }

.color-hover-dribbble:hover, .color-hover-dribbble:active, .color-hover-dribbble:focus {
color: #ed4584 !important; }

.border-color-dribbble {
color: #ed4584 !important; }

.border-color-hover-dribbble:hover, .border-color-hover-dribbble:active, .border-color-hover-dribbble:focus {
color: #ed4584 !important; }

.bg-amazon {
background-color: #ff9700 !important; }

.bg-hover-amazon:hover, .bg-hover-amazon:active, .bg-hover-amazon:focus {
background-color: #ff9700 !important; }

.color-amazon {
color: #ff9700 !important; }

.color-hover-amazon:hover, .color-hover-amazon:active, .color-hover-amazon:focus {
color: #ff9700 !important; }

.border-color-amazon {
color: #ff9700 !important; }

.border-color-hover-amazon:hover, .border-color-hover-amazon:active, .border-color-hover-amazon:focus {
color: #ff9700 !important; }

.bg-skype {
background-color: #00acf4 !important; }

.bg-hover-skype:hover, .bg-hover-skype:active, .bg-hover-skype:focus {
background-color: #00acf4 !important; }

.color-skype {
color: #00acf4 !important; }

.color-hover-skype:hover, .color-hover-skype:active, .color-hover-skype:focus {
color: #00acf4 !important; }

.border-color-skype {
color: #00acf4 !important; }

.border-color-hover-skype:hover, .border-color-hover-skype:active, .border-color-hover-skype:focus {
color: #00acf4 !important; }

.bg-instagram {
background-color: #396d9a !important; }

.bg-hover-instagram:hover, .bg-hover-instagram:active, .bg-hover-instagram:focus {
background-color: #396d9a !important; }

.color-instagram {
color: #396d9a !important; }

.color-hover-instagram:hover, .color-hover-instagram:active, .color-hover-instagram:focus {
color: #396d9a !important; }

.border-color-instagram {
color: #396d9a !important; }

.border-color-hover-instagram:hover, .border-color-hover-instagram:active, .border-color-hover-instagram:focus {
color: #396d9a !important; }

.bg-dropbox {
background-color: #0d84de !important; }

.bg-hover-dropbox:hover, .bg-hover-dropbox:active, .bg-hover-dropbox:focus {
background-color: #0d84de !important; }

.color-dropbox {
color: #0d84de !important; }

.color-hover-dropbox:hover, .color-hover-dropbox:active, .color-hover-dropbox:focus {
color: #0d84de !important; }

.border-color-dropbox {
color: #0d84de !important; }

.border-color-hover-dropbox:hover, .border-color-hover-dropbox:active, .border-color-hover-dropbox:focus {
color: #0d84de !important; }

.bg-flickr {
background-color: #ea0066 !important; }

.bg-hover-flickr:hover, .bg-hover-flickr:active, .bg-hover-flickr:focus {
background-color: #ea0066 !important; }

.color-flickr {
color: #ea0066 !important; }

.color-hover-flickr:hover, .color-hover-flickr:active, .color-hover-flickr:focus {
color: #ea0066 !important; }

.border-color-flickr {
color: #ea0066 !important; }

.border-color-hover-flickr:hover, .border-color-hover-flickr:active, .border-color-hover-flickr:focus {
color: #ea0066 !important; }

.bg-github {
background-color: #2f2f2f !important; }

.bg-hover-github:hover, .bg-hover-github:active, .bg-hover-github:focus {
background-color: #2f2f2f !important; }

.color-github {
color: #2f2f2f !important; }

.color-hover-github:hover, .color-hover-github:active, .color-hover-github:focus {
color: #2f2f2f !important; }

.border-color-github {
color: #2f2f2f !important; }

.border-color-hover-github:hover, .border-color-hover-github:active, .border-color-hover-github:focus {
color: #2f2f2f !important; }

.bg-tumblr {
background-color: #304c68 !important; }

.bg-hover-tumblr:hover, .bg-hover-tumblr:active, .bg-hover-tumblr:focus {
background-color: #304c68 !important; }

.color-tumblr {
color: #304c68 !important; }

.color-hover-tumblr:hover, .color-hover-tumblr:active, .color-hover-tumblr:focus {
color: #304c68 !important; }

.border-color-tumblr {
color: #304c68 !important; }

.border-color-hover-tumblr:hover, .border-color-hover-tumblr:active, .border-color-hover-tumblr:focus {
color: #304c68 !important; }

.bg-foursquare {
background-color: #207dc5 !important; }

.bg-hover-foursquare:hover, .bg-hover-foursquare:active, .bg-hover-foursquare:focus {
background-color: #207dc5 !important; }

.color-foursquare {
color: #207dc5 !important; }

.color-hover-foursquare:hover, .color-hover-foursquare:active, .color-hover-foursquare:focus {
color: #207dc5 !important; }

.border-color-foursquare {
color: #207dc5 !important; }

.border-color-hover-foursquare:hover, .border-color-hover-foursquare:active, .border-color-hover-foursquare:focus {
color: #207dc5 !important; }
