.form-text.text-danger {
  position: absolute !important;
  z-index: 10 !important;
}

.account-item {
  cursor: pointer;
  font-size: 15px;
}

.main-title {
  font-size: 16px;
  font-weight: bold;
}

.title-item {
  font-weight: 900;
  font-size: 15px;
}

.item-name {
  margin-left: -20px !important;
}

.inative-account span{
  color: #9E9E9E;
  text-decoration-line: line-through;
}

.bg-form {
  background-color: #e7e7e7;
}