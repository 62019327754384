.rdt_TableHead {
  background: linear-gradient(#ffff, #2222) !important;
  border-top: none !important;
  border-bottom: 0.7px solid #8d8a8a !important;
}

.rdt_TableCol_Sortable {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 30px !important;
  color: #000000 !important;
}

#column-undefined {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #000000 !important;
}

.rdt_TableCell {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.rtd_button:hover {
  font-size: 30px !important;
  filter: brightness(40%) !important; 
}
