.cargo-style {
  margin: 5px;
  padding: 0;
}

.cargo-style p {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #555;
}

.cargo-style span {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.bg-card {
  background-color:#0B4050;
}
