/* .crops {
  max-width: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .crops img {
  max-width:100%;
  height:100%;
} */

/* .crop-title {
  color: #505050;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
} */

/* .crop-date {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #505050;
} */

/* .crop-number-plants {
  font-style: normal;
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  color: #53883D;
  margin-top: -15px;
} */

.card-img {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}